.section-location-nav {
  box-sizing: border-box;
  position: relative;
  width: 100%;
  background-color: #ffffff;
  .container-location-nav {
    box-sizing: border-box;
    position: relative;
    width: 100%;
    margin: 0 auto;
    padding: 10px 15px;
    .location-nav-inner {
      padding: 50px 0 0 0;
      @media (max-width: $mobile-condense) {
        padding: 30px 0 0 0;
      }
      box-sizing: border-box;
      display: flex;
      justify-content: center;
      ul.location-nav-list {
        position: relative;
        list-style: none;
        display: flex;
        justify-content: center;
        flex-direction: row;
        align-content: center;
        padding: 0;
        margin: 0;
        width: auto;
        box-sizing: border-box;
        li {
          position: relative;
          display: block;
          font-size: 14px;
          margin-right: 40px;
          @media (max-width: 600px) {
            margin-right: 20px;
          }
          &:last-child {
            margin-right: 0;
          }
          a {
            display: flex;
            align-content: center;
            align-items: center;
            text-decoration: none;
            white-space: nowrap;
            color: $color-copy-dark;
            font-weight: $app-font-bold;
            font-size: 1.2em;
            padding: 8px 8px 12px 8px;
            background-color: #ffffff;
            &:hover {
              color: $color-copy-dark-highlight;
            }
            &.active {
              &:after {
                content: "";
                display: block;
                position: absolute;
                left: 0;
                bottom: 0;
                width: 100%;
                height: 4px;
                border-radius: 2px;
                background-color: $color-active2;
              }
            }
            @media (max-width: 600px) {
              font-size: 0.9em;
              padding: 8px 8px 8px 8px;
            }
            > p {
              margin: 0;
              padding: 0;
            }
            .alertsIconAndNumber {
              position: relative;
              display: flex;
              justify-content: center;
              align-items: center;
              width: 1.5em;
              height: 1.5em;
              margin-left: 5px;
              img.alertsIcon {
                display: block;
                position: relative;
                left: 0;
                top: 0;
                width: auto;
                height: 100%;
              }
              .alertsNumber {
                display: flex;
                justify-content: center;
                align-content: center;
                align-items: center;
                position: absolute;
                right: 0;
                top: 0;
                transform: translateX(30%);
                width: 0.9em;
                height: 0.9em;
                border-radius: 50%;
                background-color: $color-active2;
                > p {
                  margin: 0;
                  padding: 0;
                  font-size: 0.7em;
                  font-weight: $app-font-regular;
                  color: #ffffff;
                }
              }
            }
          }
        }
      }
    }
  }
}
