.campaignSectionHeader {
  padding: 0 30px 30px 0;
  box-sizing: border-box;
  position: relative;
  width: 320px;
  max-width: 320px;
  &.full {
    width: 100%;
    max-width: 100%;
  }
  flex-shrink: 0;
  flex-grow: 1;
  display: flex;
  justify-content: flex-start;
  align-content: flex-start;
  align-items: flex-start;
  @media (max-width: $mobile-breakpoint) {
    padding-top: 0;
    padding: 0 0 30px 0;
    width: 100%;
    max-width: 100%;
  }
  @media (max-width: $mobile-condense) {
    max-width: 320px;
    padding: 0 0 30px 0;
    flex-wrap: wrap;
  }
  .campaignSectionHeadingGroup {
    position: relative;
    width: 100%;
    margin: 0;
    flex-shrink: 1;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-content: flex-start;
    align-items: flex-start;
    /*&.full{
            width:100%;
            margin:0 30px 10px 0;
        }*/
    @media (max-width: $mobile-condense) {
      flex-shrink: 0;
    }
    &.auto {
      width: auto;
    }
    h1 {
      width: 100%;
      margin: 0;
      line-height: 1.2em;
      font-size: 2em;
      color: $color-copy-dark;
      &.headingPart1 {
        flex-grow: 1;
        flex-shrink: 1;
        font-weight: $app-font-bold;
        @media (max-width: 950px) {
          font-size: 1.6em;
        }
      }
    }
    p.subheading {
      width: 100%;
      font-size: 1em;
      margin: 10px 0 0 0;
      color: $color-copy-dark;
      font-weight: $app-font-semibold;
      @media (max-width: 950px) {
        font-size: 0.9em;
      }
    }
    p.instructions {
      width: 100%;
      font-size: 1em;
      margin: 10px 0 0 0;
      color: $color-copy-dark;
      font-weight: $app-font-light;
      @media (max-width: 950px) {
        font-size: 0.9em;
      }
    }
  }
  .campaignSectionStepNumber {
    position: relative;
    margin: 0 15px 0 0;
    width: 36px;
    height: 36px;
    border-radius: 8px;
    @media (max-width: 950px) {
      width: 30px;
      height: 30px;
      border-radius: 6px;
    }
    @media (max-width: $mobile-condense) {
      margin: 0 0 10px 0;
    }
    background-color: $color-background-section-number;
    flex-grow: 0;
    flex-shrink: 0;
    display: flex;
    justify-content: center;
    align-content: center;
    align-items: center;
    h1.stepNumber {
      flex-grow: 0;
      flex-shrink: 0;
      font-weight: $app-font-bold;
      font-size: 1.5em;
      text-align: center;
      color: $color-copy-section-number;
      @media (max-width: 950px) {
        font-size: 1.2em;
      }
    }
  }
}
