.campaignSizePreview {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100vh;
  z-index: 9000;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-content: center;
  align-items: center;
  .previewAssetContainer {
    position: relative;
    display: block;
    width: auto;
    max-width: 90%;
    height: 80%;
    @media (max-width: $mobile-condense) {
      max-width: 100%;
    }
    background-color: transparent;

    .previewAssetContent {
      position: relative;
      height: 100%;
      max-height: 100%;
      background-color: transparent;
      display: flex;
      align-content: center;
      align-items: center;
      .placeholderContent {
        position: relative;
        width: 300px;
        height: 200px;
        max-width: 90%;
        max-height: 100%;
        background-color: #333333;
        box-shadow: 0px 3px 8px rgba(0, 0, 0, 0.5);
        display: flex;
        justify-content: center;
        align-content: center;
        align-items: center;
        p {
          text-align: center;
          padding: 0;
          margin: 0;
          font-size: 1em;
          color: #ffffff;
        }
      }
      .previewAssetContentInner {
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: center;
        margin: 0;
        width: auto;
        height: 100%;
        max-width: 100%;
        max-height: 100%;
        .closeButton {
          padding: 8px 0 0 20px;
          font-size: 1em;
          color: #ffffff;
          background: transparent;
          margin-left: auto;
          outline: 0;
          border: none;
          cursor: pointer;
          display: block;
          transform: translateY(-10px);
        }
        img {
          position: relative;
          display: block;
          width: auto;
          height: auto;
          max-width: 100%;
          max-height: 100%;
          box-shadow: 0px 3px 8px rgba(0, 0, 0, 0.5);
        }
      }

      &.16x9 {
        width: auto;
        height: auto;
        max-width: none;
        max-height: none;
      }
      .player {
        position: relative;
        width: 1920px;
        height: 1080px;
        @media (max-width: 2000px) {
          width: 1280px;
          height: 720px;
        }
        @media (max-width: 1310px) {
          width: 768px;
          height: 432px;
        }
        @media (max-width: 798px) {
          width: 640px;
          height: 360px;
        }
        @media (max-width: 670px) {
          width: 512px;
          height: 288px;
        }
        @media (max-width: 542px) {
          width: 384px;
          height: 216px;
        }
        @media (max-width: 414px) {
          width: 100%;
          height: 200px;
        }

        @media (max-height: 1080px) and (min-width: 1310px) {
          width: 1280px;
          height: 720px;
        }
        @media (max-height: 720px) and (min-width: 798px) {
          width: 768px;
          height: 432px;
        }
        @media (max-height: 432px) and (min-width: 670px) {
          width: 640px;
          height: 360px;
        }
        @media (max-height: 360px) and (min-width: 542px) {
          width: 512px;
          height: 288px;
        }
        @media (max-height: 288px) and (min-width: 414px) {
          width: 384px;
          height: 216px;
        }
        @media (max-height: 216px) {
          width: 100%;
          height: 200px;
        }
      }
    }
  }
}
