.campaignLaunch {
  position: relative;
  width: 100%;
  margin: 0 0 60px 0;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  @media (max-width: $mobile-breakpoint) {
    justify-content: center;
  }
  align-content: center;
  align-items: center;
  button {
    position: relative;
    overflow: hidden;
    padding: 15px 30px;
    margin: 0 15px;
    font-size: 1.3em;
    font-weight: $app-font-bold;
    background-color: $color-button-primary;
    box-shadow: $button-box-shadow;
    color: #ffffff;
    border: none;
    border-radius: 100px;
    outline: 0;
    cursor: pointer;
    &:hover {
      &:after {
        content: "";
        display: block;
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.1);
        border-radius: 100px;
      }
    }
    &.disabled {
      background-color: $color-button-disable;
      box-shadow: none;
    }
    span.info {
      font-size: 0.5em;
      font-weight: $app-font-regular;
    }
  }
  p {
    margin: 0 15px 20px 0;
    width: 100%;
    text-align: center;
    &.error {
      color: tomato;
    }
  }
}
