.alertOffers {
  display: block;
  width: 100%;
  ul {
    list-style: none;
    padding: 0;
    margin: 0;
    box-sizing: border-box;
    max-width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-end;
    @media (max-width: $mobile-breakpoint) {
      margin: 30px 0 0 0;
      justify-content: center;
    }
    li {
      box-sizing: border-box;
      flex-basis: 45%;
      flex-grow: 0;
      max-width: 300px;
      border-radius: 6px;
      overflow: hidden;
      box-shadow: $button-box-shadow;
      cursor: pointer;
      margin: 0 40px 40px 0;
      &:nth-child(2n) {
        margin: 0 0 40px 0;
      }
      @media (max-width: 900px) {
        margin: 0 30px 30px 0;
        &:nth-child(2n) {
          margin: 0 0 30px 0;
        }
      }
      @media (max-width: $mobile-breakpoint) {
        margin: 0 40px 40px 0;
        &:nth-child(2n) {
          margin: 0 0 40px 0;
        }
      }
      @media (max-width: $mobile-condense) {
        flex-basis: 46%;
        margin: 0 20px 20px 0;
        &:nth-child(2n) {
          margin: 0 0 20px 0;
        }
      }
      position: relative;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      &:hover {
        background-color: #eeeeee;
      }
      .highlight {
        display: none;
        position: absolute;
        box-sizing: border-box;
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
        border: 4px solid $color-active;
      }
      &.selected {
        background-color: #ffffff;
        color: $color-active;
        /*box-shadow:inset 0 0 0 4px $color-active;*/
        box-shadow: none;
        /*cursor:default;*/
        .highlight {
          display: block;
        }
      }
      .offerImage {
        display: block;
        position: relative;
        padding: 0;
        margin: 0;
        width: 100%;
        height: auto;
        background-color: #ffffff;
        &:before {
          content: "";
          display: block;
          position: relative;
          width: 100%;
          padding-bottom: 83.33%;
        }
        img {
          position: absolute;
          left: 50%;
          top: 50%;
          transform: translate(-50%, -50%);
          width: auto;
          height: auto;
          max-width: 100%;
          max-height: 100%;
        }
      }
      p.offerName {
        display: block;
        box-sizing: border-box;
        text-align: left;
        position: relative;
        padding: 15px 15px 2px 15px;
        margin: 0;
        font-size: 14px;
        font-weight: $app-font-bold;
        @media (max-width: $mobile-condense) {
          font-size: 12px;
        }
      }
      p.offerDetails {
        display: block;
        box-sizing: border-box;
        text-align: left;
        position: relative;
        padding: 2px 15px 15px 15px;
        margin: 0;
        font-size: 12px;
        @media (max-width: $mobile-condense) {
          font-size: 10px;
        }
        font-weight: $app-font-regular;
      }
    }
  }
}
