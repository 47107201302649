.campaignSubcategories {
  position: relative;
  margin: 20px 0 10px 15px;
  width: 100%;
  max-width: 100%;
  padding: 0 15px;
  @media (max-width: $mobile-breakpoint) {
    padding: 0;
    margin: 20px 0 10px 0;
  }
  ul {
    list-style: none;
    padding: 2px 0 18px 0;
    margin: 0 0 22px 0;
    width: 100%;
    overflow-x: scroll;
    overflow-y: visible;
    display: block;
    overflow-x: scroll;
    white-space: nowrap;
    @media (min-width: 721px) {
      flex-wrap: wrap;
      overflow-x: visible;
      overflow-y: visible;
      padding: 2px 0 18px 0;
    }
    li {
      display: inline-block;
      margin: 0 10px 15px 0;
      @media (max-width: $mobile-breakpoint) {
        margin: 0 10px 0 0;
        &:first-child {
          margin: 0 10px 0 30px;
        }
        &:last-child::after {
          position: relative;
          display: block;
          content: "";
          width: 30px;
          height: 1px;
          left: 100%;
          bottom: 0;
          z-index: -1;
        }
      }
      padding: 8px 16px;
      position: relative;
      color: #000000;
      font-size: 12px;
      @media (max-width: $mobile-condense) {
        font-size: 11px;
        &:first-child {
          margin: 0 10px 0 15px;
        }
      }
      font-weight: $app-font-bold;
      white-space: nowrap;
      border-radius: 100px;
      box-shadow: $button-box-shadow;
      cursor: pointer;
      &:hover {
        background-color: #eeeeee;
      }
      &.selected {
        background-color: $color-active;
        color: #ffffff;
        box-shadow: none;
        cursor: default;
      }
    }
  }
}
