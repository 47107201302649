.howItWorksContainer {
  position: relative;
  width: 100%;
  .howItWorksSection {
    position: relative;
    width: 100%;
    margin: 0;
    padding: 0;
    display: block;
    h1 {
      display: block;
      position: relative;
      width: 100%;
      margin: 0;
      color: $color-copy-dark;
      .colorHeading {
        color: $color-copy-dark;
      }
      font-size: 32px;
      font-weight: $app-font-bold;
      text-align: left;
      line-height: 1.2em;
      box-sizing: border-box;
      @media (max-width: 850px) {
        font-size: 28px;
      }
    }
    .howItWorksHero {
      position: relative;
      width: 100%;
      max-width: $max-content-width;
      margin: 40px auto;
      padding: 40px 15px;
      @media (max-width: $mobile-breakpoint) {
        margin: 0;
        padding: 40px 15px 0 15px;
        overflow: hidden;
        -webkit-backface-visibility: hidden;
        -moz-backface-visibility: hidden;
        -webkit-transform: translate3d(0, 0, 0);
        -moz-transform: translate3d(0, 0, 0);
      }
      box-sizing: border-box;
      display: block;
      img.howItWorksHeroImage {
        position: absolute;
        display: block;
        right: 36%;
        transform: translateX(50%);
        top: 0;
        height: 100%;
        width: auto;
        border-radius: 6px;
        @media (max-width: $mobile-breakpoint) {
          position: initial;
          right: 0;
          width: 100%;
          height: 200px;
          object-fit: cover;
          object-position: 50% 15%;
          border-radius: 0;
          transform: translateX(0%);
        }
      }
      .howItWorksHeroContentBox {
        position: relative;
        margin: 40px 0;
        padding: 30px 60px;
        box-sizing: border-box;
        width: 60%;
        max-width: 520px;
        border-radius: 16px;
        background-color: #ffffff;
        box-shadow: $button-box-shadow;
        @media (max-width: $mobile-breakpoint) {
          margin: 15px 0 0 0;
          padding: 0;
          width: 100%;
          max-width: 100%;
          border-radius: 0;
          background-color: transparent;
          box-shadow: none;
        }
        h1 {
          color: $color-copy-dark;
        }
        p {
          margin: 20px 0;
        }
        > a {
          position: relative;
          display: inline-block;
          overflow: hidden;
          -webkit-backface-visibility: hidden;
          -moz-backface-visibility: hidden;
          -webkit-transform: translate3d(0, 0, 0);
          -moz-transform: translate3d(0, 0, 0);
          text-align: center;
          text-decoration: none;
          margin: 10px 0;
          min-width: 200px;
          flex-grow: 0;
          padding: 10px 30px;
          font-size: 14px;
          font-weight: $app-font-bold;
          background-color: $color-button-primary;
          box-shadow: $button-box-shadow;
          color: #ffffff;
          border: none;
          border-radius: 100px;
          outline: 0;
          cursor: pointer;
          &:hover {
            &:after {
              content: "";
              display: block;
              position: absolute;
              left: 0;
              top: 0;
              width: 100%;
              height: 100%;
              background-color: rgba(0, 0, 0, 0.1);
            }
          }
          &.disabled {
            background-color: $color-button-disable;
            box-shadow: none;
          }
          @media (max-width: $mobile-breakpoint) {
            background-color: #ffffff;
            color: $color-copy-dark;
            min-width: 120px;
          }
        }
      }
    }
    .howItWorksSlideshow {
      position: relative;
      width: 100%;
      max-width: $max-content-width;
      margin: 40px auto;
      padding: 80px 15px 40px 15px;
      @media (max-width: $mobile-breakpoint) {
        padding: 0;
      }
      box-sizing: border-box;
      display: block;
      h1 {
        display: block;
        position: relative;
        width: 90%;
        margin: 0 auto;
      }
      p {
        display: block;
        position: relative;
        width: 90%;
        margin: 20px auto 5px auto;
      }
      .slideShowContent {
        position: relative;
        display: block;
        margin: 20px auto;
        width: 90%;
        height: auto;
        background-color: $color-background-dark-gray;
        &:before {
          content: "";
          display: block;
          position: relative;
          width: 100%;
          height: auto;
          padding-bottom: 56.25%;
        }
      }
      .slideShowNavSliderContainer {
        position: relative;
        margin: 0;
        width: 100%;
        height: auto;
        display: flex;
        align-content: center;
        align-items: center;
        ul.slideShowNavSlider {
          flex-grow: 0;
          position: relative;
          margin: 0;
          padding: 0;
          width: 90%;
          height: auto;
          list-style: none;
          display: block;
          overflow-x: scroll;
          white-space: nowrap;
          li {
            position: relative;
            display: inline-block;
            background-color: $color-background-dark-gray;
            width: 19.2%;
            flex-shrink: 0;
            margin-right: 1%;
            &:last-child {
              margin-right: 0;
            }
            &:before {
              content: "";
              display: block;
              position: relative;
              width: 100%;
              height: auto;
              padding-bottom: 56.25%;
            }
          }
        }
        .slideShowNavSliderArrow {
          width: 5%;
          height: 40px;
          @media (max-width: $mobile-condense) {
            height: 30px;
          }
          flex-grow: 0;
          flex-shrink: 0;
          position: relative;
          display: flex;
          justify-content: center;
          align-content: center;
          align-items: center;
          .arrowIcon {
            display: block;
            width: 60%;
            height: 100%;
            position: relative;
            .inlineSVG {
              display: block;
              position: absolute;
              left: 50%;
              top: 50%;
              transform: translate(-50%, -50%);
              width: auto;
              height: auto;
              min-width: 100%;
              min-height: 100%;
              max-width: 100%;
              max-height: 100%;
              .inlineSVGWrapper {
                display: block;
                position: absolute;
                left: 0;
                top: 0;
                width: 100%;
                height: 100%;
                svg {
                  fill: $color-button-dark-background;
                  width: 100%;
                  height: 100%;
                }
              }
            }
          }
        }
      }
    }
    .howItWorksFAQ {
      position: relative;
      width: 100%;
      max-width: $max-content-width;
      margin: 40px auto;
      padding: 80px 15px;
      box-sizing: border-box;
      display: flex;
      @media (max-width: $mobile-breakpoint) {
        flex-wrap: wrap;
        margin: 0;
      }
      .howItWorksFAQIntro {
        width: 50%;
        max-width: 280px;
        flex-shrink: 0;
        flex-grow: 0;
        margin-top: 40px;
        @media (max-width: $mobile-breakpoint) {
          width: 100%;
          max-width: 100%;
        }
      }
      ul.howItWorksFAQList {
        position: relative;
        list-style: none;
        width: 50%;
        min-width: 280px;
        padding: 0 0 0 40px;
        @media (max-width: $mobile-breakpoint) {
          width: 100%;
          max-width: 100%;
          padding: 0;
        }
        flex-shrink: 0;
        flex-grow: 1;
        display: flex;
        align-content: flex-start;
        align-items: flex-start;
        flex-wrap: wrap;
        li.faqItem {
          box-sizing: border-box;
          width: 100%;
          border-radius: 6px;
          overflow: hidden;
          -webkit-backface-visibility: hidden;
          -moz-backface-visibility: hidden;
          -webkit-transform: translate3d(0, 0, 0);
          -moz-transform: translate3d(0, 0, 0);
          position: relative;
          margin: 0 0 25px 0;
          display: block;
          &:last-child {
            margin: 0;
          }
          background-color: #ffffff;
          box-shadow: $button-box-shadow;
          &.expanded {
            -webkit-box-shadow: inset 0px 0px 0px 3px $color-active;
            -moz-box-shadow: inset 0px 0px 0px 3px $color-active;
            box-shadow: inset 0px 0px 0px 3px $color-active;
          }
          .faqCollapsedView {
            position: relative;
            min-height: 60px;
            overflow: hidden;
            -webkit-backface-visibility: hidden;
            -moz-backface-visibility: hidden;
            -webkit-transform: translate3d(0, 0, 0);
            -moz-transform: translate3d(0, 0, 0);
            display: flex;
            justify-content: space-between;
            align-content: center;
            cursor: pointer;
            p.faqLabel {
              box-sizing: border-box;
              margin: 0;
              padding: 20px 0 20px 20px;
              width: 40px;
              flex-shrink: 0;
              flex-grow: 0;
              font-size: 1em;
              font-weight: $app-font-semibold;
              text-align: left;
              @media (max-width: $mobile-condense) {
                font-size: 0.9em;
              }
            }
            p.faqQuestion {
              position: relative;
              display: block;
              flex-grow: 1;
              margin: 0;
              padding: 20px 20px 20px 5px;
              font-size: 1em;
              font-weight: $app-font-semibold;
              text-align: left;
              @media (max-width: $mobile-condense) {
                font-size: 0.9em;
              }
            }
            .faqExpandIcon {
              width: 60px;
              height: 40px;
              padding-top: 12px;
              flex-shrink: 0;
              flex-grow: 0;
              display: flex;
              justify-content: center;
              align-content: center;
              align-items: center;
              @media (max-width: $mobile-condense) {
                width: 40px;
              }
            }
          }
          .faqExpandedView {
            position: relative;
            display: flex;
            box-sizing: border-box;
            justify-content: flex-start;
            height: auto;
            max-height: 0;
            padding: 0;
            transition: 0.3s max-height ease-out;
            overflow: hidden;
            -webkit-backface-visibility: hidden;
            -moz-backface-visibility: hidden;
            -webkit-transform: translate3d(0, 0, 0);
            -moz-transform: translate3d(0, 0, 0);
            p.faqLabel {
              box-sizing: border-box;
              margin: 0;
              padding: 5px 0 20px 20px;
              width: 40px;
              flex-shrink: 0;
              flex-grow: 0;
              font-size: 1em;
              font-weight: $app-font-regular;
              text-align: left;
              @media (max-width: $mobile-condense) {
                font-size: 0.9em;
              }
            }
            p.faqAnswer {
              position: relative;
              display: block;
              margin: 0;
              padding: 5px 20px 40px 5px;
              font-size: 1em;
              font-weight: $app-font-regular;
              text-align: left;
              @media (max-width: $mobile-condense) {
                font-size: 0.9em;
              }
            }
          }
        }
      }
    }
    .howItWorksTestimonials {
      position: relative;
      width: 100%;
      max-width: $max-content-width;
      margin: 40px auto;
      padding: 0 15px;
      box-sizing: border-box;
      display: block;
    }
    .howItWorksCTA {
      position: relative;
      width: 100%;
      max-width: $max-content-width;
      margin: 40px auto;
      padding: 0 15px;
      box-sizing: border-box;
      display: block;
    }
  }
}
