.confirmationModalWrapper {
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.25);
  z-index: 20000;
  .confirmationModalContent {
    display: block;
    position: relative;
    background-color: #ffffff;
    box-sizing: border-box;
    padding: 28px 20px 20px 20px;
    border-radius: 10px;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
    width: 340px;
    max-width: 100%;
    .confirmationModalTextContainer {
      text-align: left;
      margin-bottom: 10px;
      p {
        margin: 0;
      }
      .confirmationModalText-Action {
        font-size: 1.125em;
        font-weight: bold;
      }
      .confirmationModalText-Message {
        font-size: 1em;
      }
      .confirmationModalText-Details {
        font-size: 0.75em;
      }
    }
    .confirmationModalStatusContainer {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      p {
        margin: 0;
        padding: 0;
        &.error {
          color: $color-message-fail;
        }
      }
    }
    .confirmationModalButtonsContainer {
      display: flex;
      justify-content: flex-end;
      .confirmationModalButton {
        padding: 8px 24px;
        margin-right: 15px;
        &:last-child {
          margin-right: 0;
        }
        background-color: $color-button-primary;
        box-shadow: $button-box-shadow;
        color: #fff;
        border-radius: 100px;
        border: none;
        cursor: pointer;
        font-weight: 700;
        font-size: 1em;
        &.buttonNo {
          background-color: #ffffff;
          color: #bbbab9;
          border: 2px solid #bbbab9;
          box-shadow: none;
        }
        &.warning {
          background-color: #ffffff;
          color: $color-message-fail;
          border: 2px solid $color-message-fail;
        }
      }
    }
    .confirmationModalClose {
      position: absolute;
      right: 0;
      top: 0;
      width: 36px;
      height: 36px;
      cursor: pointer;
      &:before,
      &:after {
        content: "";
        position: absolute;
        width: 16px;
        height: 2px;
        background-color: #383838;
        top: 18px;
      }
      &:before {
        -webkit-transform: rotate(45deg);
        -moz-transform: rotate(45deg);
        transform: rotate(45deg);
        left: 10px;
      }
      &:after {
        -webkit-transform: rotate(-45deg);
        -moz-transform: rotate(-45deg);
        transform: rotate(-45deg);
        right: 10px;
      }
    }
  }
}
