.contentLogin {
  position: relative;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  margin: 156px auto 0 auto;
  position: relative;
  width: 100%;
  max-width: 1024px;
  box-sizing: border-box;
  padding: 0 0 0 15px;
  @media (max-width: $mobile-breakpoint) {
    display: block;
    margin: 100px auto 0 auto;
    padding: 0;
  }
  .contentLoginForm {
    flex-grow: 0;
    flex-shrink: 0;
    position: relative;
    margin: 0 auto;
    padding: 30px;
    display: block;
    width: 100%;
    max-width: 320px;
    box-sizing: border-box;
    background-color: #ffffff;
    h1 {
      margin: 0 0 20px 0;
    }
    .loginForm {
      position: relative;
      margin: 0;
      width: auto;
      height: auto;
      display: flex;
      flex-direction: column;
      .loginFormInfoLegal {
        text-align: center;
        font-size: 0.8em;
        font-weight: $app-font-light;
      }
      .loginFormRow {
        position: relative;
        margin: 10px 0;
        display: flex;
        a {
          text-decoration: none;
          &:hover {
            text-decoration: underline;
          }
        }
        p.error {
          color: tomato;
        }
        label {
          display: flex;
          width: 100%;
          justify-content: flex-start;
          align-content: flex-start;
          align-items: center;
          flex-wrap: wrap;
          p {
            display: block;
            margin: 0;
            padding: 0;
            width: 100%;
          }
          input {
            display: block;
            width: 100%;
            box-sizing: border-box;
            border-radius: 8px;
            border: none;
            background-color: #ffffff;
            border: 1px solid #cccccc;
            font-family: $global-font-family;
            color: $color-copy-dark;
            padding: 10px;
            font-size: 1em;
          }
        }
        &.submit {
          justify-content: center;
          input.loginFormButtonSubmit {
            align-self: flex-end;
            position: relative;
            display: block;
            overflow: hidden;
            -webkit-backface-visibility: hidden;
            -moz-backface-visibility: hidden;
            -webkit-transform: translate3d(0, 0, 0);
            -moz-transform: translate3d(0, 0, 0);
            text-align: center;
            text-decoration: none;
            width: 160px;
            margin: 10px 0;
            min-width: 160px;
            flex-grow: 0;
            padding: 10px 30px;
            font-size: 14px;
            font-weight: $app-font-bold;
            background-color: $color-button-primary;
            box-shadow: $button-box-shadow;
            color: #ffffff;
            border: none;
            border-radius: 100px;
            outline: 0;
            cursor: pointer;
            &:hover {
              &:after {
                content: "";
                display: block;
                position: absolute;
                left: 0;
                top: 0;
                width: 100%;
                height: 100%;
                background-color: rgba(0, 0, 0, 0.1);
              }
            }
            &.disabled {
              background-color: $color-button-disable;
              box-shadow: none;
            }
            @media (max-width: $mobile-breakpoint) {
              background-color: #ffffff;
              color: $color-copy-dark;
              min-width: 120px;
            }
          }
        }
        &.forgotPassword {
          justify-content: center;
        }
        &.nonActivation {
          .nonActivationButton {
            display: block;
            position: relative;
            margin: 0 auto;
            padding: 5px 8px;
            text-align: center;
            color: $color-button-primary;
            border: 1px solid $color-button-primary;
            border-radius: 20px;
            cursor: pointer;
            &:hover {
              background-color: $color-background-light-blue;
            }
          }
          .nonActivationBackButton {
            display: block;
            position: relative;
            margin: 0 auto;
            padding: 2px 4px;
            text-align: center;
            color: $color-button-primary;
            cursor: pointer;
            &:hover {
              text-decoration: underline;
            }
          }
        }
      }
    }
    .message-success {
      text-align: center;
    }
  }
  .contentLoginMarketing {
    position: relative;
    display: block;
    flex-grow: 1;
    flex-shrink: 1;
    margin-left: 40px;
    @media (max-width: $max-content-width) {
      margin-left: 15px;
    }
    @media (max-width: $mobile-breakpoint) {
      margin-left: 0;
      margin-top: 40px;
    }
    img {
      position: relative;
      display: block;
      width: 100%;
      height: auto;
      &.contentLoginMarketing-img-desktop {
        @media (max-width: $mobile-breakpoint) {
          display: none;
        }
      }
      &.contentLoginMarketing-img-mobile {
        display: none;
        @media (max-width: $mobile-breakpoint) {
          display: block;
        }
      }
    }
  }
}
