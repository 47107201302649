.statusOrder {
  background-color: #ffffff;
  &.cancelled {
    display: block;
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    background-color: rgba($color: #bbbbbb, $alpha: 0.4);
    .sizeChannelsItem {
      border: 3px solid #adadad !important;
    }
    .cancelButton {
      background-color: rgba($color: #bbbbbb, $alpha: 0.4) !important;
    }
  }
  .orderExpandIcon {
    cursor: pointer;
  }
}
.orderExpandedView {
  &.isExpanded {
    max-height: unset !important;
  }
  .campaignSize {
    margin-bottom: 15px;
    padding: 0;
    width: 100%;
    ul {
      padding: 0;
    }
    li {
      .sizeCollapsedView {
        cursor: default;
        .sizeCollapsedDetails {
          justify-content: flex-start;
          .sizePrice {
            margin-left: 15px;
          }
          .sizePaymentStatusContainer {
            width: auto;
            p {
              padding: 0 15px 0 15px;
              .sizePaymentStatusLabel {
                color: $color-copy-dark;
              }
              .sizePaymentStatus {
                color: $color-copy-dark;
                &.success {
                  color: $color-copy-status-green;
                }
              }
            }
            .updatePaymentMethodButton {
              background: transparent;
              background-color: $color-button-primary;
              border: none;
              color: #ffffff;
              font-weight: 700;
              font-size: 0.8em;
              text-decoration: none;
              border-radius: 100px;
              padding: 15px 30px;
              cursor: pointer;
              outline: 0;
              box-shadow: $button-box-shadow;
            }
          }
        }
      }
      .sizeExpandedView {
        max-height: unset;
        .sizeDetails {
          max-width: 100%;
          justify-content: flex-start;
          .sizeDetailsItem {
            max-width: 138px;
            @media (max-width: $mobile-condense) {
              flex-basis: 44%;
            }
            p {
              @media (max-width: $mobile-condense) {
                padding: 8px;
                font-size: 0.8em;
                span.emphasis {
                  font-size: 1.5em;
                }
              }
            }
          }
        }
        p.sizeChannelsLabel {
          margin: 0;
          padding: 0 15px 0 15px;
          max-width: 100%;
        }
        .sizeChannels {
          max-width: 100%;
          justify-content: flex-start;
          .sizeChannelsItem {
            max-width: 138px;
            @media (max-width: $mobile-condense) {
              flex-basis: 44%;
            }
            p {
              @media (max-width: $mobile-condense) {
                padding: 8px;
                font-size: 0.8em;
              }
            }
            &.noHover {
              cursor: default;
              &:hover {
                border: 3px solid #dddddd;
              }
            }
            &.noShadow {
              box-shadow: none;
            }
          }
        }
      }
    }
  }

  .orderFooter {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    width: 100%;

    & > .cancelButton {
      margin: 15px 0;
      padding: 10px 20px;
      background-color: #ffffff;
      color: $color-button-red;
      border: 2px solid $color-button-red-transparent;
      border-radius: 100px;
      box-shadow: $button-box-shadow;
      cursor: pointer;
      font-weight: 700;
      font-size: 0.8em;
      outline: 0;
      &:disabled {
        color: $color-button-red-transparent;
        box-shadow: none;
        cursor: not-allowed;
      }
      @media (max-width: $mobile-breakpoint) {
        font-size: 0.7em;
        padding: 10px 20px;
        margin-left: 10px;
      }
    }
  }
}
.expandedCampaignSize {
  height: auto;
  padding: 5px;
  margin-bottom: 20px;
}
.demoOnlyDisclaimer {
  width: 100%;
  background-color: tomato;
  display: flex;
  h1 {
    text-align: left;
    margin: 20px 0;
  }
  img.icon-warning {
    position: relative;
    display: block;
    width: 28px;
    height: auto;
    margin: 0 20px;
  }
}
.reports {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-around;
  p.callOut {
    padding: 0 2%;
    @media screen and (max-width: 768px) {
      font-size: 14px;
    }
    @media screen and (max-width: 640px) {
      font-size: 12px;
    }
  }
  .reportsColumn {
    min-width: 405px;
    @media (max-width: $mobile-midbreak) {
      min-width: 291px;
    }
    @media (max-width: $mobile-extra-condense) {
      min-width: 200px;
    }
    &.seeReport {
      width: 100%;
      text-align: center;
      margin-top: 25px;
    }
    img {
      height: 42px;
      width: 42px;
    }
  }
  .title {
    font-size: 18px;
    margin: 20px 0 10px 0;
    text-align: left;
    @media (max-width: $mobile-midbreak) {
      font-size: 18px;
      text-align: center;
    }
    @media (max-width: $mobile-extra-condense) {
      margin-left: 10px;
      text-align: center;
    }
  }
  .number {
    font-size: 48px;
    margin: 0 15px;
    @media (max-width: $mobile-breakpoint) {
      font-size: 28px;
    }
  }
  .details {
    font-size: 14px;
    margin: -15px 0 0 15px;
    @media (max-width: $mobile-breakpoint) {
      margin: -10px 0 0 15px;
    }
  }
  img {
    margin: 15px 0 0 30px;
    @media (max-width: $mobile-breakpoint) {
      margin: 5px 0 0 30px;
    }
  }
}

.reports,
.orderDetailsColumn {
  a.reportButton,
  button.good,
  button.scheduleCall {
    background: #4ca5f8;
    padding: 10px 20px;
    font-size: 14px;
    font-weight: 700;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
    color: #ffffff;
    border: none;
    border-radius: 8px;
    outline: 0;
    cursor: pointer;
    // margin: 0 auto;
    margin-right: 8px;
    text-decoration: none;
  }
  button.scheduleCall {
    background: #ffa52e;
  }
  .ctaContainer {
    margin-top: 30px;
    width: 100%;
    display: flex;
    justify-content: center;
  }
}

.revenueContainer {
  .title {
    color: #4e7b45;
  }
  .innerContainer {
    display: flex;
    justify-content: left;
    margin: 10px 0;
    img {
      margin: 0 0 0 30px;
    }
    .number {
      color: #4e7b45;
    }
  }
}
.numbersContainer {
  .title {
    color: #3d84c6;
  }
  .revenue,
  .orders,
  .views,
  .clicks {
    margin: 10px 0;
    display: flex;
    justify-content: left;
    align-items: flex-start;
  }
  .revenue {
    color: #4e7b45;
  }
  .orders {
    color: #ffa523;
  }
  .views {
    color: $color-active;
  }
  .clicks {
    color: #7cbc6f;
  }
}

.onlineOrdersContainer {
  color: $color-copy-dark;
  .orders {
    margin: 10px 0;
    padding-right: 20px;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    @media (max-width: 889px) {
      padding: 0;
      justify-content: left;
    }
  }
}
.conversionMetrics {
  .table {
    display: flex;
    justify-content: space-between;

    .channel {
      margin-left: 10px;
    }
  }
  .table:nth-child(even) {
    background-color: #fcaa4046;
  }
  .percentage {
    margin-right: 10px;
  }
}
