.campaignLaunchOverlay {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: #ffffff;
  display: flex;
  justify-content: center;
  align-content: center;
  align-items: center;
  opacity: 0;
  transition: opacity 0.3s;
  &.enabled {
    opacity: 1;
  }
  h1.campaignLaunchOverlayTitle {
    position: relative;
    width: 100%;
    margin: 0;
    text-align: center;
  }
  #dotsAnimation {
    position: absolute;
  }
}
