.contentResetPassword {
  position: relative;
  margin: 156px auto 0 auto;
  padding: 30px;
  display: block;
  width: 100%;
  box-sizing: border-box;
  max-width: 320px;
  background-color: #ffffff;
  h1 {
    margin: 0 0 20px 0;
  }
  .resetPassword {
    position: relative;
    margin: 0;
    width: auto;
    height: auto;
    display: flex;
    flex-direction: column;
    .resetPasswordRow {
      position: relative;
      margin: 10px 0;
      display: flex;
      p.error {
        color: tomato;
      }
      label {
        display: flex;
        width: 100%;
        justify-content: flex-start;
        align-content: flex-start;
        align-items: center;
        flex-wrap: wrap;
        p {
          display: block;
          margin: 0;
          padding: 0;
          width: 100%;
        }
        input {
          display: block;
          width: 100%;
          box-sizing: border-box;
          border-radius: 8px;
          border: none;
          background-color: #ffffff;
          border: 1px solid #cccccc;
          font-family: $global-font-family;
          color: $color-copy-dark;
          padding: 10px;
          font-size: 1em;
        }
      }
      &.backToLogin {
        justify-content: center;
      }
      &.submit {
        justify-content: flex-end;
        input.resetPasswordButtonSubmit {
          align-self: flex-end;
          position: relative;
          display: block;
          overflow: hidden;
          -webkit-backface-visibility: hidden;
          -moz-backface-visibility: hidden;
          -webkit-transform: translate3d(0, 0, 0);
          -moz-transform: translate3d(0, 0, 0);
          text-align: center;
          text-decoration: none;
          width: 160px;
          margin: 10px 0;
          min-width: 160px;
          flex-grow: 0;
          padding: 10px 30px;
          font-size: 14px;
          font-weight: $app-font-bold;
          background-color: $color-button-primary;
          box-shadow: $button-box-shadow;
          color: #ffffff;
          border: none;
          border-radius: 100px;
          outline: 0;
          cursor: pointer;
          &:hover {
            &:after {
              content: "";
              display: block;
              position: absolute;
              left: 0;
              top: 0;
              width: 100%;
              height: 100%;
              background-color: rgba(0, 0, 0, 0.1);
            }
          }
          &.disabled {
            background-color: $color-button-disable;
            box-shadow: none;
          }
          @media (max-width: $mobile-breakpoint) {
            background-color: #ffffff;
            color: $color-copy-dark;
            min-width: 120px;
          }
        }
      }
    }
  }
}
