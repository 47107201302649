.campaignSelectStatus {
  position: fixed;
  right: 30px;
  top: 50%;
  transform: translateY(-50%);
  display: flex;
  flex-direction: column;
  ul.campaignSelectStatusSteps {
    list-style: none;
    padding: 0;
    margin: 0;
    display: flex;
    @media (max-width: 700px) {
      display: none;
    }
    flex-direction: column;
    justify-content: center;
    li {
      box-sizing: border-box;
      position: relative;
      width: 20px;
      height: 20px;
      border-radius: 10px;
      margin: 0 0 10px 0;
      background-color: lightgray;
      &.past {
        background-color: rgb(44, 141, 68);
        cursor: pointer;
      }
      &.active {
        background-color: rgb(59, 170, 207);
        cursor: pointer;
      }
      p {
        position: absolute;
        white-space: nowrap;
        right: 30px;
        top: 0;
        margin: 0;
        padding: 0;
        text-align: right;
        color: $color-copy-dark;
        font-size: 10px;
      }
    }
  }
}
