.datePicker {
  position: relative;
  max-width: 185px;
  .react-datepicker {
    border: 1px solid rgba(228, 228, 228, 0.6);
    border-radius: 23.5px;
  }
  .react-datepicker-popper {
    left: -100px !important;
  }
  .react-datepicker__header {
    background-color: #ffffff;
    border-bottom: none;
    border-top-left-radius: 23.5px;
    border-top-right-radius: 23.5px;
  }
  .react-datepicker__current-month {
    padding-bottom: 9px;
    padding-top: 12px;
  }
  .react-datepicker__navigation--next,
  .react-datepicker__navigation--previous {
    width: 9px;
    height: 15px;
    top: 20px;
    border: none;
  }
  .react-datepicker__navigation--next {
    background: url("/assets/images/ui/chevrons-right.svg") no-repeat;
    background-size: cover;
    margin-right: 12px;
  }
  .react-datepicker__navigation--previous {
    background: url("/assets/images/ui/chevrons-left.svg") no-repeat;
    background-size: cover;
    margin-left: 12px;
  }
  .react-datepicker__day-names {
    padding: 0 30px;
    border-top: 1px solid #dddddc;
    text-transform: uppercase;
    font-size: 11px;
    .react-datepicker__day-name {
      color: #dddddc;
    }
  }
  .react-datepicker__month {
    .react-datepicker__week {
      .react-datepicker__day--selected {
        border-radius: 50%;
        background-color: $color-active;
      }
    }
    .react-datepicker__day:hover {
      border-radius: 50%;
    }
  }
  .react-datepicker__triangle {
    display: none;
  }
  .react-datepicker__input-container {
    input {
      position: relative;
      box-shadow: 0px 2.14737px 4.29474px rgba(0, 0, 0, 0.25);
      border-radius: 53.6842px;
      border-color: transparent;
      height: 43px;
      width: 100px;
      padding: 0 40px;
      background-color: transparent;
      font-weight: $app-font-light;
      font-size: 16px;
      cursor: pointer;
      outline: none;
      z-index: 1;
    }
  }
  .datePickerIcon {
    position: absolute;
    right: 15px;
    top: 12px;
    width: 27px;
    height: 27px;
  }
}
