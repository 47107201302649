header {
  position: fixed;
  box-sizing: border-box;
  width: 100%;
  left: 0;
  top: 0;
  height: 80px;
  display: flex;
  align-items: center;
  @media (max-width: $mobile-breakpoint) {
    height: 60px;
  }
  background-color: #ffffff;
  z-index: 9000;
  .headerInner {
    position: relative;
    box-sizing: border-box;
    width: 100%;
    height: auto;
    max-width: $max-content-width;
    margin: 0 auto;
    display: flex;
    padding: 15px;
    @media (max-width: $mobile-breakpoint) {
      padding: 15px 0 15px 15px;
    }
    justify-content: space-between;
    align-content: center;
    .headerLogoContainer {
      position: relative;
      display: flex;
      .siteLogo {
        position: relative;
        height: 50px;
        @media (max-width: $mobile-breakpoint) {
          height: 40px;
        }
        flex-shrink: 0;
        img.logo-platform {
          height: 100%;
          width: auto;
        }
        img.logo-brand {
          width: auto;
          height: auto;
          max-height: 100%;
          max-width: 150px;
          @media (max-width: $mobile-breakpoint) {
            max-width: 120px;
          }
          border-left: 1px solid #727272;
          padding-left: 12px;
          margin-left: 15px;
        }
        a {
          display: block;
          text-decoration: none;
          color: #ffffff;
          position: absolute;
          left: 0;
          top: 0;
          width: 100%;
          height: 100%;
        }
      }
      .poweredBy {
        position: relative;
        margin-left: 10px;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        p {
          font-size: 8px;
          line-height: 1.5em;
          text-align: left;
          margin: 0;
          font-weight: $app-font-light;
          span {
            font-size: 10px;
            font-weight: $app-font-bold;
          }
        }
      }
    }
    nav {
      display: flex;
      align-items: center;
      top: 0;
      position: relative;
      .mobileNavOverlay {
        position: fixed;
        left: 0;
        top: 0;
        width: 100vw;
        height: 100vh;
        display: none;
        @media (max-width: $mobile-breakpoint) {
          display: block;
        }
        background-color: #000000;
        z-index: -8000;
        transform: translateZ(1000px) translateX(100%);
        opacity: 0;
        transition: 0s opacity;
        &.visible {
          z-index: 8000;
          transform: translateZ(0) translateX(0%);
          opacity: 0.7;
          transition: 0.3s opacity;
        }
      }
      .mobileNavCloseButton {
        position: fixed;
        right: 0;
        top: 0;
        width: 40px;
        height: 40px;
        display: none;
        &.visible {
          @media (max-width: $mobile-breakpoint) {
            display: block;
          }
          z-index: 8200;
        }
        .closeX {
          position: absolute;
          right: 20px;
          top: 15px;
          display: block;
          width: 16px;
          height: 16px;
          overflow: hidden;
          -webkit-backface-visibility: hidden;
          -moz-backface-visibility: hidden;
          -webkit-transform: translate3d(0, 0, 0);
          -moz-transform: translate3d(0, 0, 0);
          &:before,
          &:after {
            content: "";
            position: absolute;
            height: 3px;
            width: 100%;
            top: 50%;
            left: 0;
            background: #fff;
          }
          &:before {
            height: 3px;
            transform: rotate(45deg);
          }
          &:after {
            margin-top: 0px;
            transform: rotate(-45deg);
          }
        }
      }
      .mobileNavPoweredBy {
        position: fixed;
        right: 15px;
        bottom: 15px;
        width: auto;
        height: auto;
        display: none;
        &.visible {
          @media (max-width: $mobile-breakpoint) {
            display: block;
          }
          z-index: 8200;
        }
        p {
          text-align: right;
          bottom: 0;
          margin: 0;
          padding: 0;
          font-size: 0.8em;
          line-height: 1.2em;
          color: #ffffff;
          span {
            font-weight: $app-font-bold;
            font-size: 0.9em;
          }
        }
      }
      ul {
        position: relative;
        margin: 0;
        padding: 0;
        list-style: none;
        display: flex;
        justify-content: flex-start;
        align-content: center;
        li {
          margin-left: 30px;
          &:first-child {
            margin-left: 0;
          }
          &.legal {
            display: none;
            @media (max-width: $mobile-breakpoint) {
              display: block;
            }
          }
          a {
            display: block;
            position: relative;
            text-decoration: none;
            color: $color-copy-dark;
            padding: 5px 5px 8px 5px;
            font-size: 14px;
            cursor: pointer;
            &:after {
              content: "";
              display: none;
              position: absolute;
              left: 0;
              bottom: 0;
              height: 2px;
              width: 100%;
              background-color: $color-active;
            }
            &.active {
              &:after {
                display: block;
              }
            }
          }
        }
        @media (max-width: $mobile-breakpoint) {
          position: fixed;
          top: 0;
          right: 0;
          width: 100%;
          max-width: 300px;
          height: 100vh;
          justify-content: flex-start;
          align-content: center;
          flex-direction: column;
          background-color: $color-active;
          box-sizing: border-box;
          z-index: 8100;
          transform: translateX(100%);
          &.open {
            transform: translateX(0%);
          }
          transition: 0.2s transform ease-out;
          padding: 50px 0 0 0;
          li {
            margin-left: 0;
            a {
              padding: 15px 20px 15px 30px;
              margin-bottom: 0;
              font-size: 14px;
              font-weight: $app-font-bold;
              color: #ffffff;
              white-space: nowrap;
              box-sizing: border-box;
              cursor: pointer;
              /*&:before{
                                content:'';
                                display:none;
                                position:absolute;
                                left:6px;
                                top:10px;
                                height:1.5em;
                                width:3px;
                                background-color:$color-active;
                            }*/
              &.active {
                background-color: rgba(0, 0, 0, 0.1);
                color: #ffffff;
                &:before {
                  display: block;
                }
                &:after {
                  display: none;
                }
              }
            }
            &.mobileHide {
              display: none;
            }
          }
        }
        @media (max-width: $mobile-condense) {
          max-width: 480px;
        }
      }
      .mobileNavLink {
        display: none;
        position: relative;
        @media (max-width: $mobile-breakpoint) {
          display: flex;
          justify-content: center;
          align-content: center;
        }
        width: 30px;
        height: 40px;
        margin-right: 15px;
        a {
          display: block;
          position: absolute;
          left: 0;
          top: 0;
          width: 100%;
          height: 100%;
          text-decoration: none;
          color: $color-copy-dark;
          &.active {
            color: $color-active;
            .inlineSVG {
              .inlineSVGWrapper {
                svg {
                  fill: $color-active;
                }
              }
            }
          }
          .inlineSVG {
            position: absolute;
            width: 50%;
            height: 50%;
            top: 25%;
            left: 25%;
            .inlineSVGWrapper {
              position: absolute;
              width: 100%;
              height: 100%;
              top: 0;
              left: 0;
              display: flex;
              justify-content: center;
              svg {
                fill: #383838;
                max-width: 100%;
                max-height: 100%;
              }
            }
          }
        }
      }
      .mobileNavToggle {
        display: none;
        position: relative;
        @media (max-width: $mobile-breakpoint) {
          display: block;
        }
        width: 30px;
        height: 40px;
        margin-right: 15px;
        &.active {
          .inlineSVG {
            .inlineSVGWrapper {
              svg {
                fill: $color-active;
              }
            }
          }
        }
        .inlineSVG {
          position: absolute;
          width: 50%;
          height: 50%;
          top: 25%;
          left: 25%;
          .inlineSVGWrapper {
            position: absolute;
            width: 100%;
            height: 100%;
            top: 0;
            left: 0;
            display: flex;
            justify-content: center;
            svg {
              fill: #383838;
              max-width: 100%;
              max-height: 100%;
            }
          }
        }
      }
    }
  }
}

div.maintenance-message.visible {
  display: flex;
  justify-content: center;
  background-color: $color-active;
  margin-top: 80px;
  padding: 0 1rem;

  p {
    padding: 1rem 0;
    text-align: center;
    color: $color-background-white;
    margin: 0;
    max-width: 1000px;
  }
  button.close-maintenance-message {
    background: none;
    color: inherit;
    border: none;
    padding: 0;
    font: inherit;
    cursor: pointer;
    outline: inherit;
    margin-left: 3rem;
    @media screen and (max-width: $mobile-breakpoint) {
      margin-left: 1rem;
    }
  }
}

div.maintenance-message {
  display: none;
}
