.section-unit-selector {
  box-sizing: border-box;
  position: relative;
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  padding: 15px 15px 30px 15px;
  background-color: $color-background-light-gray;
  > p.instructions {
    position: relative;
    display: block;
    margin: 0 auto 10px auto;
    width: 100%;
    max-width: $mobile-breakpoint;
    text-align: left;
    font-size: 0.9em;
    font-weight: $app-font-light;
  }
  > ul {
    position: relative;
    list-style: none;
    margin: 0 auto;
    padding: 0;
    width: 100%;
    border-radius: 8px;
    overflow: hidden;
    -webkit-backface-visibility: hidden;
    -moz-backface-visibility: hidden;
    -webkit-transform: translate3d(0, 0, 0);
    -moz-transform: translate3d(0, 0, 0);
    -webkit-box-shadow: inset 0px 0px 0px 3px $color-button-primary;
    -moz-box-shadow: inset 0px 0px 0px 3px $color-button-primary;
    box-shadow: inset 0px 0px 0px 3px $color-button-primary;
    box-sizing: border-box;
    background-color: #ffffff;
    max-width: $mobile-breakpoint;
    display: flex;
    flex-direction: column;
    &.collapse {
      box-shadow: $button-box-shadow;
      > li.unitItem {
        display: none;
        &.selected {
          border-bottom-left-radius: 8px;
          border-bottom-right-radius: 8px;
          display: flex;
          box-shadow: none;
          /*border:3px solid #FFFFFF;*/
          > .dropdownArrow {
            opacity: 1;
            .icon-container {
              transform: rotate(0deg);
            }
          }
        }
      }
    }
    > li.unitItem {
      position: relative;
      width: 100%;
      color: $color-copy-dark;
      border: none;
      overflow: hidden;
      -webkit-backface-visibility: hidden;
      -moz-backface-visibility: hidden;
      -webkit-transform: translate3d(0, 0, 0);
      -moz-transform: translate3d(0, 0, 0);
      box-sizing: border-box;
      cursor: pointer;
      order: 2;
      display: flex;
      justify-content: space-between;
      /*border-left:3px solid $color-button-primary;
            border-right:3px solid $color-button-primary;
            &:first-child{
                border-top-left-radius:8px;
                border-top-right-radius:8px;
                border-top:3px solid $color-button-primary;
            }
            &:last-child{
                border-bottom-left-radius:8px;
                border-bottom-right-radius:8px;
                border-bottom:3px solid $color-button-primary;
            }*/
      &:hover {
        background-color: rgba(198, 229, 251, 0.3);
      }
      &.selected {
        /*border:3px solid $color-button-primary;*/
        -webkit-box-shadow: inset 0px 0px 0px 3px $color-button-primary;
        -moz-box-shadow: inset 0px 0px 0px 3px $color-button-primary;
        box-shadow: inset 0px 0px 0px 3px $color-button-primary;
        border-radius: 8px;
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
        order: 1;
        &:hover {
          background-color: #ffffff;
        }
        > ul.unitItemDetails {
          > li {
            &.dropdown {
              display: flex;
              @media (max-width: $mobile-breakpoint) {
                display: none;
              }
            }
            &.balance {
              display: flex;
            }
            &.unitAndAddress {
              border-right: 2px solid #bbbab9;
              @media (max-width: $mobile-breakpoint) {
                border-right: none;
              }
            }
          }
        }
        > .dropdownArrow {
          opacity: 1;
        }
      }
      > ul.unitItemDetails {
        position: relative;
        width: 100%;
        flex-grow: 1;
        flex-shrink: 1;
        list-style: none;
        padding: 0 15px;
        @media (max-width: $mobile-condense) {
          padding: 0 5px;
        }
        margin: 8px auto;
        max-width: $max-content-width;
        box-sizing: border-box;
        display: flex;
        justify-content: flex-start;
        flex-direction: row;
        align-content: center;
        > li {
          box-sizing: border-box;
          position: relative;
          display: flex;
          justify-content: center;
          align-items: center;
          padding: 3px 20px;
          @media (max-width: $mobile-breakpoint) {
            padding: 3px 10px;
          }
          @media (max-width: $mobile-condense) {
            padding: 5px 10px;
          }
          font-size: 14px;
          color: $color-copy-dark;
          border-right: 2px solid #bbbab9;
          &:first-child {
            padding: 0 20px 0 0;
            @media (max-width: $mobile-breakpoint) {
              padding: 0 10px 0 0;
            }
            @media (max-width: $mobile-condense) {
              padding: 0 5px 0 15px;
            }
            flex-grow: 1;
          }
          &:last-child {
            border-right: none;
            padding: 0 0 0 20px;
            @media (max-width: $mobile-breakpoint) {
              padding: 10px 0 10px 15px;
            }
            @media (max-width: $mobile-condense) {
              padding: 5px 0 5px 0;
            }
            flex-shrink: 0;
          }
          p {
            margin: 0;
            padding: 0;
          }
          &.balance {
            display: flex;
            flex-grow: 0;
            flex-shrink: 0;
            /*width:220px;*/
            font-size: 10px;
            /*@media(max-width:$mobile-condense){
                            display:none;
                        }*/
            @media (max-width: $mobile-breakpoint) {
              border-right: none;
            }
            > p {
              width: 100%;
              text-align: left;
              span {
                font-size: 14px;
                font-weight: $app-font-bold;
              }
            }
            /*@media(max-width:$mobile-condense){
                            display:none;
                        }*/
          }
          &.dropdown {
            flex-grow: 0;
            flex-shrink: 0;
            width: 130px;
            @media (max-width: $mobile-breakpoint) {
              display: none;
              width: 60px;
            }
            @media (max-width: $mobile-condense) {
              display: none;
              width: 40px;
            }
            /*display:none;*/
            display: flex;
            justify-content: space-between;
            @media (max-width: $mobile-breakpoint) {
              justify-content: center;
            }
            align-items: center;
            align-content: center;
            .text {
              display: block;
              position: relative;
              flex-shrink: 0;
              flex-grow: 0;
              margin-right: 20px;
              @media (max-width: $mobile-breakpoint) {
                display: none;
              }
              span.bold {
                font-weight: $app-font-bold;
              }
            }
          }
          &.unitAndAddress {
            @media (max-width: $mobile-breakpoint) {
              border-right: none;
            }
            > ul {
              list-style: none;
              padding: 0;
              margin: 0;
              width: 100%;
              height: 100%;
              display: flex;
              justify-content: flex-start;
              flex-direction: row;
              align-content: center;
              @media (max-width: $mobile-condense) {
                flex-wrap: wrap;
              }
              > li {
                position: relative;
                display: flex;
                justify-content: flex-start;
                align-items: center;
                flex-grow: 0;
                padding: 0 15px 0 0;
                border-right: 2px solid #bbbab9;
                &:last-child {
                  border-right: none;
                  flex-grow: 3;
                  padding: 0 0 0 15px;
                }
                &.unitNumber {
                  flex-grow: 0;
                  flex-shrink: 0;
                  box-sizing: border-box;
                  width: 170px;
                  font-weight: $app-font-bold;
                  font-size: 16px;
                  display: flex;
                  align-content: center;
                  align-items: center;
                  justify-content: flex-start;
                  @media (max-width: $mobile-breakpoint) {
                    font-size: 14px;
                    width: 160px;
                  }
                  @media (max-width: $mobile-condense) {
                    width: 100%;
                    border-right: none;
                    font-size: 12px;
                    justify-content: flex-start;
                  }
                }
                &.address {
                  flex-grow: 1;
                  justify-content: flex-start;
                  @media (max-width: $mobile-condense) {
                    padding: 0;
                  }
                  > p {
                    font-size: 12px;
                    text-align: left;
                  }
                }
                > p {
                  margin: 0;
                  padding: 0;
                }
                /*@media (max-width: $mobile-breakpoint) {
                                    width:100%;
                                    padding:0;
                                    border-right:none;
                                    &:last-child{
                                        padding:0;
                                    }
                                }*/
              }
            }
          }
        }
      }
      .dropdownArrow {
        position: relative;
        flex-grow: 0;
        flex-shrink: 0;
        width: auto;
        height: auto;
        padding: 0 10px;
        display: flex;
        align-content: center;
        align-items: center;
        background-color: $color-button-primary;
        opacity: 0;
        .icon-container {
          display: flex;
          justify-content: center;
          align-items: center;
          position: relative;
          flex-shrink: 0;
          flex-grow: 0;
          padding: 8px;
          transform: rotate(-180deg);
          transition: 0.2s transform ease-in-out;
          background-color: transparent;
          @media (max-width: $mobile-breakpoint) {
            background-color: transparent;
          }
          border-radius: 50%;
          .chevron {
            display: block;
            border-right: 3px solid #ffffff;
            border-bottom: 3px solid #ffffff;
            width: 8px;
            height: 8px;
            transform: translateY(-2px) rotate(45deg);
            @media (max-width: $mobile-breakpoint) {
              border-right: 3px solid #ffffff;
              border-bottom: 3px solid #ffffff;
            }
          }
        }
      }
    }
  }
}
