.landingPageContainer {
  position: relative;
  width: 100%;
  color: $color-copy-dark;
  a.button {
    position: relative;
    display: inline-block;
    overflow: hidden;
    -webkit-backface-visibility: hidden;
    -moz-backface-visibility: hidden;
    -webkit-transform: translate3d(0, 0, 0);
    -moz-transform: translate3d(0, 0, 0);
    text-align: center;
    text-decoration: none;
    margin: 10px 0;
    min-width: 100px;
    flex-grow: 0;
    padding: 12px 50px;
    font-size: 18px;
    font-weight: $app-font-bold;
    background-color: $color-button-primary;
    box-shadow: $button-box-shadow;
    color: #ffffff;
    border: none;
    border-radius: 100px;
    outline: 0;
    cursor: pointer;
    &:hover {
      &:after {
        content: "";
        display: block;
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.1);
      }
    }
    &.disabled {
      background-color: $color-button-disable;
      box-shadow: none;
    }
    &.white {
      background-color: #ffffff;
      color: $color-copy-dark;
    }
  }
  .landingPageSection {
    position: relative;
    width: 100%;
    margin: 0;
    padding: 0;
    display: block;
    &.transparent {
      background-color: #f4f4f4;
    }
    &.dark {
      background-color: #383838;
      color: #ffffff;
    }
    h1 {
      display: block;
      position: relative;
      width: 100%;
      margin: 0;
      color: $color-copy-dark;
      font-size: 32px;
      font-weight: $app-font-bold;
      text-align: left;
      line-height: 1.2em;
      box-sizing: border-box;
      @media (max-width: 850px) {
        font-size: 28px;
      }
    }
    .landingPageSectionHeading {
      position: relative;
      display: block;
      padding: 120px 0 40px 0;
      @media (max-width: $mobile-breakpoint) {
        padding: 80px 0 20px 0;
      }
      h2 {
        display: block;
        position: relative;
        width: 100%;
        margin: 0;
        color: $color-copy-dark;
        font-size: 42px;
        font-weight: $app-font-bold;
        text-align: center;
        line-height: 1.2em;
        box-sizing: border-box;
        @media (max-width: 850px) {
          font-size: 32px;
        }
      }
      hr {
        display: block;
        position: relative;
        width: 80%;
        max-width: 350px;
        margin: 70px auto 20px auto;
        border-top: 2px solid #bbbab9;
        border-right: none;
        border-bottom: none;
        border-left: none;
        box-sizing: border-box;
        box-shadow: none;
        @media (max-width: $mobile-breakpoint) {
          margin: 35px auto 20px auto;
        }
      }
    }
    .landingPageHero {
      position: relative;
      width: 100%;
      max-width: 100%;
      margin: 40px auto;
      padding: 80px 0 40px 15px;
      box-sizing: border-box;
      display: flex;
      justify-content: flex-end;
      @media (max-width: $mobile-breakpoint) {
        flex-wrap: wrap;
        padding: 40px 0 60px 24px;
      }
      .landingPageHeroImageRight {
        position: relative;
        max-width: 40%;
        @media (max-width: $mobile-breakpoint) {
          flex-wrap: wrap;
          width: 100%;
          max-width: 100%;
          margin-top: 30px;
        }
        img {
          display: block;
          width: 100%;
          height: auto;
        }
      }
      .landingPageHeroContentBox {
        position: relative;
        margin: 40px 0;
        padding: 0 60px 0 30px;
        box-sizing: border-box;
        width: 60%;
        flex-grow: 0;
        flex-shrink: 0;
        max-width: 650px;
        background-color: #ffffff;
        @media (max-width: $mobile-breakpoint) {
          margin: 15px 0 0 0;
          padding: 0 24px 0 0;
          width: 100%;
          max-width: 100%;
        }
        h1 {
          color: $color-copy-dark;
          font-size: 50px;
          line-height: 1.2em;
          @media (max-width: $breakpoint-tablet) {
            font-size: 36px;
          }
        }
        p {
          margin: 20px 0;
          font-size: 24px;
          line-height: 1.4em;
          @media (max-width: $breakpoint-tablet) {
            font-size: 16px;
          }
        }
      }
    }
    .landingPageContent2Col {
      box-sizing: border-box;
      display: flex;
      @media (max-width: $mobile-breakpoint) {
        flex-wrap: wrap;
      }
      padding: 75px 0;
      @media (max-width: $breakpoint-tablet) {
        padding: 34px 0 64px 0;
      }
      .landingPageContentLeft {
        padding: 0 30px 0 0;
      }
      .landingPageContentRight {
        padding: 0 0 0 30px;
      }
      .landingPageContentLeft,
      .landingPageContentRight {
        display: flex;
        flex-wrap: wrap;
        box-sizing: border-box;
        width: 50%;
        flex-shrink: 0;
        flex-grow: 0;
        align-content: flex-start;
        @media (max-width: $mobile-breakpoint) {
          width: 100%;
          padding: 0;
        }
      }
      .landingPageContent2ColImage {
        display: block;
        width: 100%;
        height: auto;
        @media (max-width: $mobile-breakpoint) {
          transform: translateX(-20px);
        }
      }
      .landingPageContent2ColText {
        margin: 90px 0 0 0;
        padding: 0 200px 0 0;
        @media (max-width: $breakpoint-large) {
          padding: 0 120px 0 0;
        }
        @media (max-width: $breakpoint-min-hd) {
          padding: 0 60px 0 0;
        }
        @media (max-width: $breakpoint-tablet) {
          margin: 50px 0 0 0;
        }
        @media (max-width: $mobile-breakpoint) {
          padding: 0 24px;
        }
        h2 {
          width: 100%;
          font-size: 32px;
          line-height: 1.3em;
          margin: 0;
        }
        p {
          width: 100%;
          margin: 30px 0 0 0;
          font-size: 20px;
          line-height: 1.5em;
          @media (max-width: $breakpoint-tablet) {
            font-size: 14px;
          }
        }
      }
    }
    ol.landingPageContent4Col {
      position: relative;
      list-style: none;
      display: flex;
      justify-content: flex-start;
      width: 100%;
      max-width: 1280px;
      box-sizing: border-box;
      padding: 0 40px 60px 40px;
      margin: 0 auto;
      @media (max-width: $breakpoint-tablet) {
        flex-wrap: wrap;
        justify-content: space-around;
        padding: 0 30px 60px 30px;
      }
      @media (max-width: $mobile-breakpoint) {
        justify-content: flex-start;
      }
      @media (max-width: $mobile-condense) {
        padding: 0 24px 60px 24px;
      }
      li {
        width: 25%;
        @media (max-width: $breakpoint-tablet) {
          width: 45%;
        }
        box-sizing: border-box;
        padding: 20px 5px 20px 3px;
        @media (max-width: $mobile-breakpoint) {
          width: 100%;
          display: flex;
          align-items: flex-start;
          padding: 20px 0;
        }
        img.landingPageContent4ColImage {
          position: relative;
          display: block;
          width: 100%;
          height: auto;
          @media (max-width: $mobile-breakpoint) {
            width: 155px;
          }
          @media (max-width: $mobile-condense) {
            width: 120px;
          }
        }
        .landingPageContent4ColText {
          position: relative;
          display: block;
          margin: 10px 0;
          padding: 0 8px;
          @media (max-width: $mobile-breakpoint) {
            margin: 0;
            padding: 0 0 0 8px;
          }
          h4 {
            position: relative;
            display: block;
            font-size: 24px;
            font-weight: 600;
            margin: 0 0 7px 0;
            @media (max-width: $mobile-breakpoint) {
              font-size: 16px;
            }
          }
          p {
            position: relative;
            display: block;
            font-size: 20px;
            margin: 0;
            @media (max-width: $mobile-breakpoint) {
              font-size: 14px;
            }
            strong {
              font-weight: 600;
            }
          }
        }
      }
    }
    .landingPageCTA {
      position: relative;
      width: 100%;
      max-width: $max-content-width;
      margin: 0 auto;
      padding: 90px 24px;
      @media (max-width: $mobile-breakpoint) {
        padding: 60px 24px 40px 24px;
      }
      box-sizing: border-box;
      display: flex;
      flex-direction: column;
      align-items: center;
      .landingPageTextPlusList {
        margin: 0;
        font-size: 24px;
        text-align: center;
        @media (max-width: $breakpoint-tablet) {
          font-size: 18px;
        }
        @media (max-width: $mobile-breakpoint) {
          font-size: 14px;
        }
      }
      h3 {
        margin: 24px 0 40px 0;
        font-size: 32px;
        text-align: center;
        @media (max-width: $breakpoint-tablet) {
          font-size: 24px;
        }
        @media (max-width: $mobile-breakpoint) {
          font-size: 18px;
        }
      }
      span.nobreak {
        white-space: nowrap;
      }
    }
    .landingPageContactUs {
      position: relative;
      box-sizing: border-box;
      width: 100%;
      max-width: 1170px;
      margin: 0 auto;
      padding: 0 30px;
      .landingPageContactUsInnerContent {
        position: relative;
        width: 100%;
        padding: 30px 0;
        border-bottom: 1px solid #dddddc;
        display: flex;
        justify-content: space-between;
        align-items: center;
        @media (max-width: $mobile-breakpoint) {
          flex-direction: column;
          padding: 27px 0 20px 0;
        }
        img.landingPageContactUsLogo {
          display: block;
          position: relative;
          height: 50px;
          width: auto;
        }
        .button {
          @media (max-width: $mobile-breakpoint) {
            margin-top: 24px;
            width: 100%;
            box-sizing: border-box;
          }
        }
      }
    }
  }
}
