.discountContainer {
  margin-top: 20px;
  .discountDetail {
    display: inline-block;
  }
  .discountDetailEdit,
  .discountDetailDelete {
    background: transparent;
    border: none;
    color: $color-button-dark-background;
    cursor: pointer;
    text-decoration: underline;
  }
  .discountDetailDelete {
    margin-left: 10px;
  }
  .discountForm {
    display: flex;
    align-items: baseline;
    @media (max-width: $mobile-extra-condense) {
      flex-wrap: wrap;
    }
  }
  .discountError {
    color: $color-message-fail;
    display: inline;
  }
  .discountIcon {
    padding: 0 5px;
  }
  label {
    position: relative;
    max-width: 200px;
    margin-right: 20px;
    @media (max-width: $mobile-extra-condense) {
      width: 100%;
      margin-bottom: 10px;
    }
  }
  .discountPlaceholderIcon {
    position: absolute;
    top: 14px;
    left: 15px;
    display: inline-block;
    width: 15px;
    height: 15px;
    background-image: url("/assets/images/ui/promo-code.svg");
    background-size: 15px 15px;
  }
  input {
    margin: 0;
    padding: 10px 14px 10px 36px;
    font-size: 14px;
    box-shadow: rgba(50, 50, 93, 0.14902) 0px 1px 3px, rgba(0, 0, 0, 0.0196078) 0px 1px 0px;
    outline: 0;
    border-radius: 4px;
    border: solid 3px transparent;
    background: white;
    &::placeholder {
      color: #aab7c4;
    }
    &:focus {
      box-shadow: rgba(50, 50, 93, 0.109804) 0px 4px 6px, rgba(0, 0, 0, 0.0784314) 0px 1px 3px;
      -webkit-transition: all 150ms ease;
      transition: all 150ms ease;
      border: solid $color-active;
      + .discountPlaceholderIcon {
        background-image: url("/assets/images/ui/promo-code-blue.svg");
      }
    }
    &.error {
      border: solid 3px $color-message-fail;
      + .discountPlaceholderIcon {
        background-image: url("/assets/images/ui/promo-code-red.svg");
      }
    }
  }
  .promoCodeSubmitButton {
    overflow: hidden;
    text-align: center;
    text-decoration: none;
    margin: 0;
    min-width: 86px;
    padding: 10px 30px;
    font-size: 14px;
    font-weight: 700;
    background-color: $color-button-primary;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
    color: #ffffff;
    border: none;
    border-radius: 100px;
    outline: 0;
    cursor: pointer;
    &:disabled {
      background-color: $color-button-disable;
      pointer-events: none;
    }
  }
}
