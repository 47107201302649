form.contactFormHideSuccess {
  animation: hideFormSuccess 2s linear forwards;
  pointer-events: none;
}

@keyframes hideFormSuccess {
  0% {
  }
  30%,
  100% {
    opacity: 0;
  }
}

.showContactMessageSuccess {
  animation: showMessageSuccess 2s linear forwards;
}

@keyframes showMessageSuccess {
  0% {
    opacity: 0;
  }
  30%,
  100% {
    opacity: 1;
  }
}

.contact-message {
  position: absolute;
  top: 300px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 1;
  font-size: 30px;
  opacity: 0;
  white-space: nowrap;
  span {
    &.success {
      color: $color-message-success;
    }
    &.fail {
      color: $color-message-fail;
    }
  }
}
.showContactMessage {
  animation: showMessage 2s linear;
}
@keyframes showMessage {
  0% {
    opacity: 0;
  }
  30%,
  70% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

.contactContainer {
  position: relative;
  width: 100%;
  max-width: 640px;
  box-sizing: border-box;
  padding: 0 15px;
  margin: 80px auto;
  h1.contactHeading {
    font-size: 32px;
    font-weight: $app-font-bold;
    text-align: left;
    line-height: 1.2em;
    width: 100%;
    box-sizing: border-box;
    margin: 0 auto;
    padding: 0;
    color: $color-copy-dark;
    @media (max-width: 850px) {
      font-size: 28px;
    }
  }
  p.contactSubheading {
    font-size: 0.8em;
    font-weight: $app-font-light;
    text-align: left;
    width: 100%;
    box-sizing: border-box;
    margin: 20px auto;
    color: $color-copy-dark;
  }
  form.contactFormHide {
    animation: hideForm 2s linear;
  }
  @keyframes hideForm {
    0% {
    }
    30%,
    70% {
      opacity: 0;
    }
    100% {
    }
  }

  form.contactForm {
    display: flex;
    flex-direction: column;

    .contactFormRow {
      position: relative;
      display: flex;
      flex-wrap: wrap;
      box-sizing: border-box;
      width: 100%;
      margin-bottom: 20px;
      .calendly-iframe {
        min-width: 320px;
        width: 100%;
        height: 630px;
        border: none;
        display: block;
        &.display-none {
          display: none;
        }
      }
      &:last-child {
        margin-bottom: 0;
      }
      &.contentRight {
        justify-content: flex-end;
      }
      .selectContainer {
        position: relative;
        display: block;
        overflow: hidden;
        -webkit-backface-visibility: hidden;
        -moz-backface-visibility: hidden;
        -webkit-transform: translate3d(0, 0, 0);
        -moz-transform: translate3d(0, 0, 0);
        width: 100%;
        height: 40px;
        /*border:1px solid #CCCCCC;*/
        border: none;
        border-radius: 8px;
        box-shadow: $button-box-shadow;
        select {
          position: relative;
          display: block;
          width: 100%;
          height: 100%;
          padding: 10px;
          font-size: 1em;
          color: $color-copy-dark;
          background-color: #ffffff;
          border: none;
          -webkit-appearance: none;
          -moz-appearance: none;
          appearance: none;
          -ms-word-break: normal;
          word-break: normal;
          &::-ms-expand {
            display: none;
          }
        }
        .dropdownArrow {
          position: absolute;
          right: 0;
          top: 0;
          width: auto;
          height: 100%;
          padding: 0 10px;
          display: flex;
          align-content: center;
          align-items: center;
          background-color: $color-button-primary;
          pointer-events: none;
          .icon-container {
            display: flex;
            justify-content: center;
            align-items: center;
            position: relative;
            flex-shrink: 0;
            flex-grow: 0;
            padding: 8px;
            transition: 0.2s transform ease-in-out;
            background-color: transparent;
            @media (max-width: $mobile-breakpoint) {
              background-color: transparent;
            }
            border-radius: 50%;
            .chevron {
              display: block;
              border-right: 3px solid #ffffff;
              border-bottom: 3px solid #ffffff;
              width: 8px;
              height: 8px;
              transform: translateY(-2px) rotate(45deg);
              @media (max-width: $mobile-breakpoint) {
                border-right: 3px solid #ffffff;
                border-bottom: 3px solid #ffffff;
              }
            }
          }
        }
      }
      textarea {
        width: 100%;
        height: 300px;
        border-radius: 8px;
        border: none;
        background-color: #ffffff;
        font-family: $global-font-family;
        color: $color-copy-dark;
        padding: 10px;
        font-size: 1em;
        resize: none;
        box-shadow: $button-box-shadow;
      }
      .contactFormSubmitButton {
        position: relative;
        display: block;
        overflow: hidden;
        -webkit-backface-visibility: hidden;
        -moz-backface-visibility: hidden;
        -webkit-transform: translate3d(0, 0, 0);
        -moz-transform: translate3d(0, 0, 0);
        text-align: center;
        text-decoration: none;
        margin: 0;
        min-width: 300px;
        @media (max-width: $mobile-condense) {
          min-width: 200px;
          width: 100%;
        }
        padding: 10px 30px;
        font-size: 14px;
        font-weight: $app-font-bold;
        background-color: $color-button-primary;
        box-shadow: $button-box-shadow;
        color: #ffffff;
        border: none;
        border-radius: 100px;
        outline: 0;
        cursor: pointer;
        &:hover {
          &:after {
            content: "";
            display: block;
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            background-color: rgba(0, 0, 0, 0.1);
          }
        }
        &.disabled {
          background-color: $color-button-disable;
          box-shadow: none;
        }
      }
    }
  }
}
.contact-footer {
  position: relative;
  display: block;
  padding: 0;
  margin: 0;
  width: 100%;
  background: $color-background-light-gray;
  .contact-footer-inner {
    position: relative;
    display: flex;
    justify-content: center;
    align-content: center;
    align-items: center;
    @media (max-width: $mobile-condense) {
      flex-direction: column;
    }
    padding: 40px 0 20px 0;
    width: 100%;
    max-width: 640px;
    margin: 0 auto;
    img {
      position: relative;
      display: block;
      height: 200px;
      width: 200px;
    }
    .address {
      position: relative;
      display: block;
      font-size: 1em;
      color: $color-copy-dark;
      padding-left: 40px;
      @media (max-width: $mobile-condense) {
        padding-left: 0;
        padding-top: 20px;
        width: auto;
      }
      span {
        position: relative;
        display: block;
      }
    }
  }
}
