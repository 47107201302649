.campaignPromotions {
  flex-grow: 1;
  flex-shrink: 1;
  ul {
    list-style: none;
    padding: 0;
    margin: 0;
    width: 100%;
    max-width: 720px;
    display: flex;
    flex-wrap: wrap;
    justify-content: left;
    li {
      box-sizing: border-box;
      flex-basis: 24%;
      margin: 0 0 10px 0;
      padding: 3px 6px 10px 6px;
      border-radius: 10px;
      /*&:nth-child(4n + 4){
                margin:0 0 10px 0;
            }*/
      @media (max-width: 550px) {
        flex-basis: 33%;
        /*&:nth-child(4n + 4){
                    margin:0 10px 10px 0;
                }
                &:nth-child(3n){
                    margin:0 0 10px 0;
                }*/
      }
      /*@media (max-width: 450px) {
                flex-basis:28%;
            }*/
      position: relative;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;

      .promotionIconContainer {
        position: relative;
        .offers-badge {
          position: absolute;
          right: -10px;
          top: -10px;
          min-width: 12px;
          min-height: 12px;
          line-height: 12px;
          padding: 5px;
          color: $color-background-white;
          background-color: $color-active;
          border: 3px solid $color-background-white;
          border-radius: 20px;
          text-align: center;
          font-weight: $app-font-bold;
        }
        &:hover {
          cursor: pointer;
          /*background-color:#EEEEEE;*/
          .inlineSVG {
            .inlineSVGWrapper {
              svg {
                fill: #505050;
              }
              &:after {
                content: "";
                display: block;
                position: absolute;
                left: 0;
                top: 0;
                width: 100%;
                height: 100%;
                background-color: rgba(0, 0, 0, 0.1);
                border-radius: 8px;
              }
            }
          }
        }
      }
      &.selected {
        -webkit-box-shadow: inset 0px 0px 0px 3px $color-active2;
        -moz-box-shadow: inset 0px 0px 0px 3px $color-active2;
        box-shadow: inset 0px 0px 0px 3px $color-active2;
        .promotionIconContainer {
          /*background-color:$color-active;*/
          box-shadow: none;
          /*&:hover{
                        background-color:$color-active;
                    }*/
          .inlineSVG {
            .inlineSVGWrapper {
              svg {
                fill: #ffffff;
              }
            }
          }
        }
        /*cursor:default;*/
        p {
          color: $color-active2;
        }
        &:hover {
          .promotionIconContainer {
            .inlineSVG {
              .inlineSVGWrapper {
                &:after {
                  display: none;
                }
              }
            }
          }
        }
      }
      &.disable {
        &:hover {
          .promotionIconContainer {
            cursor: default;
            .inlineSVG {
              .inlineSVGWrapper {
                &:after {
                  display: none;
                }
              }
            }
          }
        }
      }
      .promotionIconContainer {
        display: block;
        position: relative;
        margin: 10px auto 5px auto;
        box-sizing: border-box;
        width: 80%;
        box-shadow: $button-box-shadow;
        border-radius: 8px;
        &:before {
          content: "";
          display: block;
          position: relative;
          width: 100%;
          height: auto;
          padding-bottom: 100%;
        }
        .inlineSVG {
          display: block;
          position: absolute;
          left: 50%;
          top: 50%;
          transform: translate(-50%, -50%);
          width: auto;
          height: auto;
          min-width: 100%;
          min-height: 100%;
          max-width: 100%;
          max-height: 100%;
          .inlineSVGWrapper {
            display: block;
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            svg,
            img {
              fill: #383838;
              width: 100%;
              height: 100%;
            }
          }
        }
        .promotionIconOverlayComingSoon {
          position: absolute;
          left: 50%;
          top: 50%;
          transform: translate(-50%, -50%);
          width: 90%;
          height: 90%;
          background-repeat: no-repeat;
          background-size: contain;
          background-position: center center;
          background-color: transparent;
          &.odd {
            background-image: url("/assets/images/promotions/promotion-coming-soon-red.png");
          }
          &.even {
            background-image: url("/assets/images/promotions/promotion-coming-soon-blue.png");
          }
        }
      }
      p {
        display: block;
        box-sizing: border-box;
        text-align: center;
        position: relative;
        padding: 4px 0 0 0;
        margin: 0;
        width: 100%;
        font-weight: $app-font-bold;
        font-size: 0.9em;
        line-height: 1.3em;
        color: $color-copy-dark;
        @media (max-width: 1024px) {
          font-size: 0.8em;
        }
        @media (max-width: 450px) {
          font-size: 0.7em;
        }
      }
    }
  }
}
