.globalSubnav {
  box-sizing: border-box;
  position: relative;
  width: 100%;
  height: 76px;
  display: flex;
  align-items: center;
  @media (max-width: $mobile-breakpoint) {
    height: 60px;
  }
  background-color: $color-background-light-gray;
  > ul {
    position: relative;
    box-sizing: border-box;
    width: 100%;
    max-width: $max-content-width;
    list-style: none;
    margin: 0 auto;
    padding: 0 10px;
    display: flex;
    align-items: center;
    > li {
      a {
        text-decoration: none;
        display: block;
        position: relative;
        color: $color-copy-dark;
        font-weight: $app-font-light;
        font-size: 12px;
        line-height: 1.3em;
        padding: 15px 7px;
        .icon-arrow-left {
          display: inline-block;
          vertical-align: middle;
          margin-right: 20px;
          height: 1em;
        }
      }
    }
  }
}
