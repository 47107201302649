.campaignScheduleSelection {
  position: relative;
  width: 100%;
  max-width: 480px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  .campaignScheduleOptionsContainer {
    margin: 10px 0;
    position: relative;
    width: 100%;
    margin: 0 0 40px 0;
    box-sizing: border-box;
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    .datePicker {
      margin-top: 10px;
      input {
        color: $color-button-disable;
      }
      // fixes safari disabled date not showing (Safari 10.1+)
      @media not all and (min-resolution: 0.001dpcm) {
        input:disabled {
          color: grey;
        }
      }
    }
    .campaignScheduleOptionsTitle {
      font-size: 24px;
      font-weight: $app-font-bold;
      color: $color-copy-dark;
      line-height: 1em;
      margin: 0 20px 0 0;
      @media (max-width: $mobile-condense) {
        font-size: 20px;
      }
    }
    .campaignScheduleOptionsItem {
      position: relative;
      border-radius: 20px;
      box-shadow: $button-box-shadow;
      border: none;
      padding: 0;
      margin: 0 0 20px 0;
      flex-basis: 100%;
      flex-shrink: 1;
      flex-grow: 1;
      box-sizing: border-box;
      background-color: #ffffff;
      max-width: 215px;
      &.campaignGreyOut {
        box-shadow: none;
        pointer-events: none;
        opacity: 0.5;
      }
      &:last-child {
        margin: 0 0 0 0;
      }
      label {
        box-sizing: border-box;
        position: relative;
        padding: 20px;
        width: 100%;
        height: 100%;
        cursor: pointer;
        display: flex;
        flex-wrap: wrap;
        .containerLabelAndOptions {
          box-sizing: border-box;
          position: relative;
          padding: 0;
          margin: 0;
          width: 100%;
          display: flex;
          align-content: center;
          align-items: center;
          flex-direction: column;
          @media (max-width: $mobile-extra-condense) {
            flex-wrap: wrap;
          }
          p.labelText {
            color: $color-button-disable;
            flex-grow: 1;
            flex-shrink: 1;
            margin: 0;
            padding: 0;
            font-size: 18px;
            span {
              font-size: 15px;
              line-height: 20px;
              padding-top: 10px;
              display: inline-block;
              font-weight: normal;
            }
            @media (max-width: $mobile-extra-condense) {
              flex-basis: 100%;
            }
          }
          .campaignScheduleDetailsRange {
            position: relative;
            width: 160px;
            height: 40px;
            margin: 0;
            @media (max-width: $mobile-extra-condense) {
              margin: 8px 0 0 0;
            }
            border-radius: 20px;
            overflow: hidden;
            -webkit-backface-visibility: hidden;
            -moz-backface-visibility: hidden;
            -webkit-transform: translate3d(0, 0, 0);
            -moz-transform: translate3d(0, 0, 0);
            background-color: #ffffff;
            box-shadow: $button-box-shadow;
            border: 1px solid #cccccc;
            display: flex;
            justify-content: space-between;
            align-items: center;
            align-content: center;
            p {
              display: block;
              text-align: center;
              position: absolute;
              z-index: 50;
              width: 100%;
              margin: 0;
              box-sizing: border-box;
              font-size: 16px;
              @media (max-width: $mobile-condense) {
                font-size: 16px;
              }
              font-weight: $app-font-light;
            }
            button {
              position: relative;
              z-index: 100;
              padding: 0;
              margin-right: 0;
              width: 40px;
              height: 100%;
              border: none;
              border-radius: 0;
              outline: 0;
              background-color: rgba(0, 0, 0, 0);
              cursor: pointer;
              &:hover {
                background-color: rgba(0, 0, 0, 0.1);
              }
              display: flex;
              justify-content: center;
              align-content: center;
              img {
                width: 16px;
                height: auto;
              }
            }
          }
        }
        ul.containerRunDates {
          list-style: none;
          width: 100%;
          box-sizing: border-box;
          padding: 0;
          margin: 10px 0 0 0;
          display: flex;
          flex-direction: column;
          li {
            width: 100%;
            padding: 0;
            margin: 0;
            font-size: 16px;
            font-weight: $app-font-regular;
            &.dateAfterLastAvailable {
              color: red;
              text-decoration: line-through;
            }
            span.datesLabel {
              font-size: 1em;
              font-weight: $app-font-bold;
            }
            span.datesDate {
              font-size: 1em;
              font-weight: $app-font-regular;
            }
          }
        }
        .radioInput {
          position: absolute;
          opacity: 0;
          height: 0;
          width: 0;
        }
        .checkmark {
          position: absolute;
          box-sizing: border-box;
          top: 16px;
          right: 10px;
          height: 30px;
          width: 30px;
          background-color: #ffffff;
          border: 1px solid #cccccc;
          border-radius: 50%;
          &:after {
            content: "";
            position: absolute;
            display: none;
            left: 9px;
            top: 4px;
            width: 8px;
            height: 14px;
            border: solid white;
            border-width: 0 3px 3px 0;
            -webkit-transform: rotate(45deg);
            -ms-transform: rotate(45deg);
            transform: rotate(45deg);
          }
          &.checked {
            background-color: $color-active;
            border: none;
            &:after {
              display: block;
            }
          }
        }
      }
      font-size: 1.5em;
      font-weight: $app-font-bold;
      line-height: 1.1em;
      color: $color-copy-dark;
      span {
        font-size: 0.6em;
        font-weight: $app-font-light;
      }
      &.selected {
        -webkit-box-shadow: inset 0px 0px 0px 3px $color-active;
        -moz-box-shadow: inset 0px 0px 0px 3px $color-active;
        box-shadow: inset 0px 0px 0px 3px $color-active;
        .datePicker input {
          color: black;
        }
        label {
          p.labelText {
            color: $color-active;
            span {
              color: $color-copy-dark;
            }
          }
        }
      }
      &.disabled {
        color: $color-button-disable;
        box-shadow: none;
        label {
          cursor: default;
        }
      }
    }
    @media (max-width: $mobile-condense) {
      margin: 0 0 20px 0;
      .campaignScheduleOptionsItem {
        font-size: 1.3em;
        label {
          padding: 15px 15px 15px 40px;
          .checkmark {
            position: absolute;
            box-sizing: border-box;
            top: 12px;
            left: 8px;
            height: 24px;
            width: 24px;
            &:after {
              left: 8px;
              top: 3px;
              width: 6px;
              height: 12px;
              border-width: 0 3px 3px 0;
            }
          }
        }
      }
    }
  }
  .campaignScheduleDetailsRow {
    position: relative;
    width: 100%;
    margin: 0 0 40px 0;
    display: flex;
    justify-content: flex-end;
    align-content: center;
    align-items: center;
    &.campaignLength {
      justify-content: space-between;
      .campaignScheduleDetailsLabel {
        text-align: initial;
      }
    }
    &.dateDetails {
      justify-content: space-between;
      p {
        font-size: 18px;
        margin: 0;
        font-weight: 600;
        span {
          font-weight: 400;
          margin-left: 28px;
        }
      }
      @media (max-width: $mobile-condense) {
        flex-direction: column;
        p {
          font-size: 15px;
          width: 100%;
          &:not(:last-child) {
            margin-bottom: 5px;
          }
        }
      }
    }
    .campaignScheduleDetailsLabel {
      font-size: 24px;
      @media (max-width: $mobile-condense) {
        font-size: 20px;
      }
      font-weight: $app-font-bold;
      color: $color-copy-dark;
      line-height: 1em;
      margin: 0 20px 0 0;
      text-align: right;
      width: 200px;
      span {
        font-size: 18px;
        @media (max-width: $mobile-condense) {
          font-size: 14px;
        }
        font-weight: $app-font-regular;
        color: $color-copy-dark;
      }
    }
    .campaignScheduleDetailsOption {
      width: 200px;
      min-width: 175px;
      height: 40px;
      margin: 0;
      border-radius: 20px;
      overflow: hidden;
      -webkit-backface-visibility: hidden;
      -moz-backface-visibility: hidden;
      -webkit-transform: translate3d(0, 0, 0);
      -moz-transform: translate3d(0, 0, 0);
      background-color: #ffffff;
      box-shadow: $button-box-shadow;
      &.campaignScheduleDetailsRange {
        width: 220px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        align-content: center;
        p {
          margin: 0;
          font-size: 18px;
          @media (max-width: $mobile-condense) {
            font-size: 16px;
          }
          font-weight: $app-font-light;
        }
        button {
          padding: 0;
          margin-right: 0;
          width: 50px;
          height: 100%;
          background-color: #ffffff;
          border: none;
          border-radius: 0;
          outline: 0;
          cursor: pointer;
          &:hover {
            background-color: #eeeeee;
          }
          display: flex;
          justify-content: center;
          align-content: center;
          img {
            width: 16px;
            height: auto;
          }
        }
      }
    }
    label {
      position: relative;
      display: block;
      width: 100%;
      max-width: 480px;
      box-sizing: border-box;
      padding: 0 0 0 40px;
      font-size: 14px;
      @media (max-width: $mobile-condense) {
        font-size: 12px;
      }
      cursor: pointer;
      input {
        position: absolute;
        opacity: 0;
        height: 0;
        width: 0;
      }
      .checkmark {
        position: absolute;
        top: 0;
        left: 0;
        height: 20px;
        width: 20px;
        background-color: #ffffff;
        border: 2px solid $color-button-dark-background;
        border-radius: 4px;
        &:after {
          content: "";
          position: absolute;
          display: none;
          left: 6px;
          top: 2px;
          width: 5px;
          height: 10px;
          border: solid white;
          border-width: 0 3px 3px 0;
          -webkit-transform: rotate(45deg);
          -ms-transform: rotate(45deg);
          transform: rotate(45deg);
        }
        &.checked {
          background-color: $color-active;
          &:after {
            display: block;
          }
        }
      }
    }
    > button {
      padding: 8px 15px;
      margin-right: 0;
      font-size: 14px;
      font-weight: $app-font-bold;
      background-color: $color-button-dark-background;
      color: #ffffff;
      border: none;
      border-radius: 100px;
      outline: 0;
      cursor: pointer;
      &:hover {
        background-color: $color-button-dark-highlight;
      }
    }
  }
}
