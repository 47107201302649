.thankYouContainer {
  position: relative;
  width: 100%;
  background-color: #ffffff;
  .thankYouContent {
    width: 100%;
    max-width: 1040px;
    margin: 0 auto;
    box-sizing: border-box;
    padding: 80px 15px;
    position: relative;
    display: flex;
    justify-content: space-between;
    align-content: flex-start;
    align-items: flex-start;
    .thankYouInnerContent {
      position: relative;
      margin: 0 80px;
      @media (max-width: $mobile-breakpoint) {
        width: 100%;
        margin: 0;
      }
      flex-grow: 1;
      flex-shrink: 1;
      display: flex;
      flex-wrap: wrap;
      align-content: flex-start;
      align-items: flex-start;
      img.thankYouIcon {
        position: relative;
        display: block;
        height: 60px;
        width: auto;
      }
      h1 {
        display: block;
        position: relative;
        width: 100%;
        margin: 15px 0;
        .colorHeading {
          color: $color-copy-header;
        }
        font-size: 32px;
        font-weight: $app-font-bold;
        text-align: left;
        line-height: 1.2em;
        box-sizing: border-box;
        @media (max-width: 850px) {
          font-size: 28px;
        }
      }
      p {
        position: relative;
        display: block;
        width: 100%;
        box-sizing: border-box;
        margin: 10px 0;
        font-size: 1em;
        font-weight: $app-font-regular;
        text-align: left;
      }
      p.bold {
        font-weight: $app-font-bold;
      }
      p.legal {
        font-size: 11px;
      }
      > a {
        position: relative;
        display: block;
        overflow: hidden;
        -webkit-backface-visibility: hidden;
        -moz-backface-visibility: hidden;
        -webkit-transform: translate3d(0, 0, 0);
        -moz-transform: translate3d(0, 0, 0);
        text-align: center;
        text-decoration: none;
        margin: 20px 0;
        padding: 10px 30px;
        font-size: 14px;
        font-weight: $app-font-bold;
        background-color: $color-button-primary;
        box-shadow: $button-box-shadow;
        color: #ffffff;
        border: none;
        border-radius: 100px;
        outline: 0;
        cursor: pointer;
        &:hover {
          &:after {
            content: "";
            display: block;
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            background-color: rgba(0, 0, 0, 0.1);
          }
        }
        &.disabled {
          background-color: $color-button-disable;
          box-shadow: none;
        }
      }
    }
    .thankYouPattern {
      @media (max-width: $mobile-breakpoint) {
        display: none;
      }
      flex-grow: 0;
      flex-shrink: 0;
      width: 200px;
      height: auto;
      img.patternIMG {
        display: block;
        position: relative;
        width: 100%;
        height: auto;
      }
    }
  }
}
