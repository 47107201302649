.statusContainer {
  position: relative;
  width: 100%;
  .statusSection {
    position: relative;
    width: 100%;
    box-sizing: border-box;
    margin: 0 auto;
    &:first-child {
      margin: 30px auto 0 auto;
    }
    padding: 40px 0 60px 0;
    background-color: #f4f4f4;
    &:nth-child(2n) {
      background-color: #ffffff;
    }
    h1.statusSectionHeading {
      font-size: 32px;
      font-weight: $app-font-bold;
      text-align: left;
      line-height: 1.2em;
      width: 100%;
      max-width: $max-content-width;
      box-sizing: border-box;
      margin: 0 auto 20px auto;
      padding: 0 15px;
      @media (max-width: 850px) {
        font-size: 28px;
      }
      span.part1 {
        color: $color-copy-dark;
      }
      span.part2 {
        color: $color-copy-dark;
      }
    }
    ul {
      list-style: none;
      padding: 0 15px;
      box-sizing: border-box;
      margin: 0 auto;
      width: 100%;
      max-width: $max-content-width;
      position: relative;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      li {
        box-sizing: border-box;
        width: 100%;
        border-radius: 6px;
        overflow: hidden;
        -webkit-backface-visibility: hidden;
        -moz-backface-visibility: hidden;
        -webkit-transform: translate3d(0, 0, 0);
        -moz-transform: translate3d(0, 0, 0);
        position: relative;
        margin: 0 0 25px 0;
        &:last-child {
          margin: 0;
        }
        box-shadow: $button-box-shadow;
        .orderCollapsedView {
          position: relative;
          overflow: hidden;
          -webkit-backface-visibility: hidden;
          -moz-backface-visibility: hidden;
          -webkit-transform: translate3d(0, 0, 0);
          -moz-transform: translate3d(0, 0, 0);
          display: flex;
          justify-content: space-between;
          align-content: center;
          .orderLabel {
            background-color: $color-active;
            color: #ffffff;
            position: relative;
            display: flex;
            flex-direction: column;
            justify-content: center;
            width: 90px;
            @media (max-width: $mobile-condense) {
              width: 80px;
            }
            flex-grow: 0;
            flex-shrink: 0;
            p {
              margin: 0;
              padding: 0 5px;
              font-size: 28px;
              font-weight: $app-font-bold;
              text-align: center;
              @media (max-width: $mobile-condense) {
                font-size: 22px;
              }
            }
          }
          .orderCollapsedDetails {
            background-color: transparent;
            color: $color-copy-dark;
            position: relative;
            min-height: 60px;
            display: flex;
            justify-content: space-between;
            align-content: center;
            flex-grow: 1;
            .orderEstimatedResults {
              flex-grow: 1;
              display: flex;
              justify-content: flex-start;
              align-items: center;
              align-content: center;
              &.pointer {
                cursor: pointer;
              }
              img.icon-warning {
                position: relative;
                display: block;
                width: 28px;
                height: auto;
                margin-left: 20px;
              }
              p {
                margin: 0;
                padding: 10px 5px 10px 20px;
                font-size: 12px;
                .orderEstimatedResultsLabel {
                  font-size: 16px;
                }
                font-weight: $app-font-regular;
                text-align: left;
                line-height: 1.3em;
                @media (max-width: $mobile-condense) {
                  padding: 10px 5px 10px 10px;
                  font-size: 12px;
                  .orderEstimatedResultsLabel {
                    font-size: 14px;
                  }
                }
                sup {
                  font-size: 0.5em;
                }
                .infoLink {
                  display: inline-block;
                  width: 8px;
                  height: 8px;
                  border-radius: 40px;
                  background-color: $color-button-dark-background;
                }
              }
            }
            .orderAutoRepeatActive {
              display: flex;
              align-items: center;
              align-content: center;
              margin: 0 10px 0 0;
              .autoRepeatIconContainer {
                display: flex;
                align-items: center;
                .autoRepeatIcon {
                  position: relative;
                  display: block;
                  width: 16px;
                  height: 28px;
                  @media (max-width: $mobile-condense) {
                    width: 16px;
                    height: 28px;
                  }
                  fill: $color-button-off;
                  &.active {
                    fill: $color-active;
                  }
                  &.lock {
                    margin-top: 5px;
                  }
                  svg {
                    max-width: 100%;
                    max-height: 100%;
                  }
                }
              }
            }
            .orderDate {
              width: 105px;
              @media (max-width: $mobile-condense) {
                width: 92px;
              }
              flex-grow: 0;
              flex-shrink: 0;
              display: flex;
              flex-direction: column;
              justify-content: center;
              p {
                margin: 0;
                padding: 0 0 0 0;
                font-size: 16px;
                .orderDateLabel {
                  font-size: 12px;
                  white-space: nowrap;
                }
                text-align: left;
                line-height: 1.2em;
                @media (max-width: $mobile-condense) {
                  font-size: 14px;
                  padding: 0;
                }
              }
            }
            .orderExpandIcon {
              width: 60px;
              display: flex;
              justify-content: center;
              align-content: center;
              align-items: center;
              @media (max-width: $mobile-condense) {
                width: 30px;
              }
            }
          }
        }
        .orderExpandedView {
          position: relative;
          display: flex;
          box-sizing: border-box;
          flex-wrap: wrap;
          justify-content: flex-start;
          height: auto;
          max-height: 0;
          padding: 0 20px;
          transition: 0.3s max-height ease-out;
          .orderDetailsMain {
            position: relative;
            width: 100%;
            box-sizing: border-box;
            display: flex;
            justify-content: flex-start;
            align-content: flex-start;
            align-items: flex-start;
            margin: 20px 0;
            @media (max-width: $mobile-breakpoint) {
              flex-wrap: wrap;
            }
            img.orderDetailsOfferImage {
              flex-grow: 0;
              flex-shrink: 0;
              display: block;
              position: relative;
              padding: 0;
              margin: 0;
              width: 100%;
              max-width: 300px;
              height: auto;
              background-color: #000000;
              border-radius: 6px;
            }
            .orderDetailsColumnsContainer {
              flex-grow: 1;
              display: flex;
              justify-content: space-between;
              margin-left: 30px;
              @media (max-width: $mobile-breakpoint) {
                width: 100%;
                margin-left: 0;
                flex-wrap: wrap;
              }
              .orderDetailsColumn {
                display: flex;
                flex-direction: column;
                justify-content: flex-start;
                margin-right: 30px;
                @media (max-width: $mobile-breakpoint) {
                  flex-basis: 100%;
                  margin-top: 20px;
                }
                .orderDetailsRow {
                  width: 100%;
                  margin-top: 18px;
                  &:first-child {
                    margin-top: 0;
                  }
                  span.orderDetailsRowLabel {
                    font-size: 0.8em;
                    font-weight: $app-font-light;
                  }
                }
              }
            }
          }
          .orderDetailsItemsContainer {
            box-sizing: border-box;
            display: flex;
            justify-content: center;
            flex-wrap: wrap;
            padding: 15px;
            position: relative;
            box-sizing: border-box;
            margin: 0 auto;
            width: 100%;
            .orderDetailsItem {
              position: relative;
              flex-basis: 28%;
              flex-grow: 0;
              background-color: #eeeeee;
              border-radius: 2px;
              margin: 0 10px 10px 0;
              min-height: 80px;
              &:nth-child(3n) {
                margin: 0 0 10px 0;
              }
              @media (max-width: $mobile-condense) {
                flex-basis: 45%;
                margin: 0 10px 10px 0;
                &:nth-child(3n) {
                  margin: 0 10px 10px 0;
                }
                &:nth-child(2n) {
                  margin: 0 0 10px 0;
                }
              }
              > p {
                display: block;
                position: relative;
                box-sizing: border-box;
                padding: 15px;
                margin: 0;
                text-align: center;
                font-size: 0.8em;
                font-weight: $app-font-light;
                color: $color-copy-dark;
                > span.emphasis {
                  font-size: 2em;
                  font-weight: $app-font-bold;
                }
                > span.labelAfter {
                  font-size: 0.8em;
                }
                img.iconSocial {
                  display: inline-block;
                  margin: 0.5em 0.5em 0.2em 0.5em;
                  height: 2em;
                  width: auto;
                }
              }
            }
          }
          .orderDetailsCopy {
            position: relative;
            width: 100%;
            padding: 0;
            display: flex;
            justify-content: center;
            p {
              font-size: 8px;
              color: $color-copy-dark;
              margin: 0;
              padding: 0 45px;
            }
          }
          .orderSelectContainer {
            position: relative;
            width: 100%;
            padding: 20px 0;
            display: flex;
            justify-content: center;
            button {
              padding: 8px 45px;
              font-size: 14px;
              font-weight: $app-font-bold;
              background-color: #999999;
              color: #ffffff;
              border: none;
              border-radius: 100px;
              outline: 0;
              cursor: pointer;
              &:hover {
                background-color: #777777;
              }
              &.selected {
                background-color: $color-active;
              }
            }
          }
          .orderLegal {
            position: relative;
            width: 100%;
            padding: 15px;
            font-size: 8px;
            color: $color-copy-dark;
          }

          .campaignSize .sizeChannels .sizeChannelsItem {
            cursor: default;
            &:hover {
              border: 3px solid #dddddd;
            }
            box-shadow: none;
            .sizeChannelsItemPreviewButton {
              display: none;
            }
          }
        }
        &.expanded {
          -webkit-box-shadow: inset 0px 0px 0px 3px $color-active;
          -moz-box-shadow: inset 0px 0px 0px 3px $color-active;
          box-shadow: inset 0px 0px 0px 3px $color-active;
        }
      }
    }
  }
}

@import "./components/status-items/StatusItem.module.scss";
