@import url("https://fonts.googleapis.com/css?family=Poppins:300,400,500,600,700&display=swap&subset=devanagari,latin-ext");
$global-font-family: "Poppins", sans-serif;
$app-font-light: 300;
$app-font-regular: 400;
$app-font-semibold: 600;
$app-font-bold: 700;

html {
  padding: 0;
  margin: 0;
}
body {
  padding: 0;
  margin: 0;
  font-family: $global-font-family;
  font-size: 16px;
  -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
  -webkit-tap-highlight-color: transparent;
  background-color: #f4f4f4;
}

.contentInner {
  position: relative;
  max-width: $max-content-width;
  margin: 0 auto;
  padding: 0 15px;
}

.noselect {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
                                    supported by Chrome and Opera */
}
.notaphighlight {
  -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
  -webkit-tap-highlight-color: transparent;
}
.nowrap {
  white-space: nowrap;
}
