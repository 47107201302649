.campaignNextSectionMessageContainer {
  display: block;
  position: fixed;
  left: 50%;
  bottom: 75px;
  overflow: hidden;
  transform: translate(-50%, 0%);
  transition: 0.3s transform ease-out, 0.1s opacity;
  &.hide {
    transform: translate(-50%, 200%);
    opacity: 0;
  }
  > p.message {
    position: relative;
    padding: 0;
    margin: 0;
    font-size: 14px;
    font-weight: $app-font-bold;
    color: $color-active;
    box-sizing: border-box;
    transform: translate(0%, 0%);
    transition: none;
    transition-delay: 0;
    z-index: 100;
  }
}
