.otnotice {
  // Position the terms below the header and fix navigation scroll.
  .otnotice-section-content {
    position: relative;
    top: 100px;
  }
  // Lift left menu to the top of the terms so it's clickable on mobile screens.
  .otnotice-menu,
  .otnotice-menu-mobile {
    z-index: 100;
    top: 100px;
  }

  // Terms version position.
  .otnotice-version {
    font-size: 12px;
    margin-top: 120px;
    .otnotice-public-version,
    .otnotice-timestamp {
      display: block;
      text-align: right;
    }
  }
  .api-error {
    text-align: center;
    margin-top: 100px;
  }
}
