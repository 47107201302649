footer {
  position: relative;
  width: 100%;
  box-sizing: border-box;
  left: 0;
  top: 0;
  display: flex;
  align-items: center;
  &.gray {
    background-color: $color-background-light-gray;
  }
  p.footer-legal {
    position: relative;
    display: block;
    margin: 0 auto;
    padding: 40px 15px 40px 15px;
    box-sizing: border-box;
    text-align: center;
    color: gray;
    font-size: 0.7em;
    @media (max-width: $mobile-breakpoint) {
      font-size: 0.6em;
    }
  }
}
