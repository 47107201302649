.reportContainer {
  position: relative;
  width: 100%;
}
.reportSection {
  position: relative;
  width: 100%;
  box-sizing: border-box;
  margin: -40px auto 25px auto;
  padding: 0 15px 20px 15px;
  background-color: #f4f4f4;

  .report {
    box-sizing: border-box;
    margin: 0 auto;
    padding: 0 20px;
    width: 100%;
    max-width: 995px;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25); // $button-box-shadow
    background-color: #ffffff;
    border-radius: 6px;

    &.reportCTA {
      background-color: #4ca5f8;
      color: #fff;
      text-align: center;
      padding: 15px;
      margin-top: 40px;
      @media (max-width: 768px) {
        text-align: left;
      }

      p {
        padding: 0px 30px 30px 0px;
        margin: 0;
      }

      a.reportCtaButton {
        background: #ffa52e;
        padding: 10px 30px;
        font-size: 14px;
        font-weight: 700;
        box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
        color: #ffffff;
        border: none;
        border-radius: 8px;
        outline: 0;
        cursor: pointer;
        margin: 0 auto;
        text-decoration: none;
      }
    }

    .reportBox {
      -webkit-box-shadow: inset 0px 0px 0px 3px #4ca5f8;
      -moz-box-shadow: inset 0px 0px 0px 3px #4ca5f8;
      box-shadow: inset 0px 0px 0px 3px #4ca5f8;
      margin: 15px 0;
      border-radius: 6px;

      .reportHeader {
        box-sizing: border-box;
        position: relative;
        display: flex;
        justify-content: space-between;
        align-content: center;
        height: 60px;
        width: 100%;
        overflow: hidden;
        -webkit-backface-visibility: hidden;
        -moz-backface-visibility: hidden;
        -webkit-transform: translate3d(0, 0, 0);
        -moz-transform: translate3d(0, 0, 0);
        border-top-right-radius: 6px;
        border-top-left-radius: 6px;
        font-size: 18px;
        font-weight: 500;
        border-bottom: 3px solid #4ca5f8;
        background-color: #4ca5f8;
        color: #fff;
        @media (max-width: 991px) {
          flex-direction: column;
        }
        @media (max-width: 768px) {
          justify-content: space-around;
        }

        .reportLabel {
          background-color: #4ca5f8;
          color: #ffffff;
          position: relative;
          display: flex;
          flex-direction: column;
          justify-content: center;
          flex-grow: 0;
          flex-shrink: 0;
          .inlineSVG {
            height: 30px;
            position: absolute;
            left: 10px;
            fill: #ffffff;
            @media (max-width: 991px) {
              top: 15px;
            }
          }
          h2 {
            text-align: left;
            margin-left: 30px;
            @media (max-width: 991px) {
              margin-bottom: 0;
              margin-left: 0;
              align-items: center;
              text-align: center;
            }
            @media (max-width: 575px) {
              font-size: 12px;
              text-align: center;
              margin-left: 0;
              margin-top: 20px;
            }
          }

          p {
            margin: 0 0 0 15px;
            padding: 0 5px;
            font-size: 28px;
            font-weight: 700;
          }
        }

        .reportHeaderDetail {
          box-sizing: border-box;
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: space-between;
          padding: 0 15px;
          @media (max-width: 991px) {
            font-size: 14px;
          }
          @media (max-width: 768px) {
            flex-direction: column;
          }
          @media (max-width: 575px) {
            font-size: 10px;
            font-weight: 600;
          }
        }
        .reportStartDate {
          flex-direction: column;
          align-items: flex-end;
          justify-content: center;
          background-color: #4ca5f8;
          color: #fff;
          font-size: 14px;
          display: flex;
          align-items: center;
          padding: 0 15px;
          text-align: right;
          @media (max-width: 991px) {
            .dateTitle {
              margin-right: 8px;
            }
            font-size: 12px;
            justify-content: center;
            flex-direction: row;
          }
          @media (max-width: 575px) {
            font-size: 10px;
          }
        }
      }

      .dataContainerRow {
        display: flex;
        flex-flow: row wrap;
        justify-content: space-around;
        margin: 15px 0;

        .dataContainerCol {
          min-width: 200px;
          text-align: center;
          h3 {
            font-weight: 500;
            margin: 0.5rem 0;
          }
          &.hide {
            display: none;
          }
          img {
            height: 42px;
            width: 42px;
          }
        }
      }

      h2 {
        padding: 0 15px;
        text-align: center;

        @media (max-width: 991px) {
          font-size: 1em;
        }
      }

      #chartGroup {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;

        .chartContainer {
          width: 50%;
          @media (max-width: 768px) {
            width: 100%;
            flex-direction: column;
          }

          &.error {
            padding: 20px;
            text-align: center;
          }

          &.fullWidth {
            width: 100%;
          }
          &.hide {
            display: none;
          }
          p {
            text-align: center;
          }
          button.changeView {
            background: #ffa52e;
            padding: 10px 30px;
            font-size: 14px;
            font-weight: 700;
            box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
            color: #ffffff;
            border: none;
            border-radius: 8px;
            outline: 0;
            cursor: pointer;
            margin: 0 auto;
            text-decoration: none;
          }
          h3 {
            text-align: center;
            margin-bottom: -25px;
          }
          .lineChart,
          .barChart,
          .barChartHorizontal {
            height: 400px;
            .horizBarLabel {
              font-weight: 700;
            }
          }
          .pieChart {
            height: 375px;
            // padding: 20px;
          }
        }
        .ctaContainer {
          display: flex;
          width: 100%;
          justify-content: center;
          margin: 15px auto;
          button {
            padding: 10px 30px;
            font-size: 14px;
            font-weight: 700;
            box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
            color: #ffffff;
            border: none;
            border-radius: 8px;
            outline: 0;
            cursor: pointer;
            // margin: 0 auto;
            margin-right: 8px;
            text-decoration: none;
          }
          .good {
            background: #4ca5f8;
          }
          .scheduleCall {
            background: #ffa52e;
          }
        }
      }
    }
    p.numbers {
      font-size: 30px;
      margin: 0;
      &.revenue {
        color: #4e7b45;
      }
      &.impressions {
        color: #4ca5f8;
      }
      &.clicks {
        color: #7cbc6f;
      }
      &.orders {
        color: #ffa523;
      }
    }
    @media (max-width: 768px) {
      p.numbers {
        font-size: 25px;
        margin-bottom: 25px;
      }
    }
  }
}
