.accountContainer {
  position: relative;
  width: 100%;
  .accountSection {
    position: relative;
    box-sizing: border-box;
    width: 100%;
    max-width: $max-content-width;
    margin: 40px auto;
    padding: 0 15px;
    display: flex;
    justify-content: flex-start;
    align-content: flex-start;
    align-items: flex-start;
    .accountInfoContainer {
      flex-basis: 100%;
      position: relative;
      display: flex;
      flex-direction: column;
      h1 {
        display: block;
        position: relative;
        width: 100%;
        margin: 0;
        color: $color-copy-dark;
        .colorHeading {
          color: $color-copy-dark;
        }
        font-size: 32px;
        font-weight: $app-font-bold;
        text-align: left;
        line-height: 1.2em;
        box-sizing: border-box;
        @media (max-width: 850px) {
          font-size: 28px;
        }
      }
      .accountInfoDetails {
        display: flex;
        flex-direction: column;
        margin: 20px 0 0 0;
        .accountInfoRow {
          word-break: break-word;
          display: flex;
          position: relative;
          width: 100%;
          padding: 0 0 20px 0;
          font-size: 1em;
          @media (max-width: $mobile-condense) {
            font-size: 0.8em;
          }
          span.accountInfoRowLabel {
            display: inline-block;
            width: 140px;
            flex-shrink: 0;
            font-weight: $app-font-light;
            @media (max-width: $mobile-condense) {
              width: 100px;
            }
          }
        }
        .paymentOptionAddCard {
          margin: 0;
          box-sizing: border-box;
          width: 100%;
          max-width: 480px;
          border-radius: 6px;
          overflow: hidden;
          -webkit-backface-visibility: hidden;
          -moz-backface-visibility: hidden;
          -webkit-transform: translate3d(0, 0, 0);
          -moz-transform: translate3d(0, 0, 0);
          position: relative;
          box-shadow: $button-box-shadow;
          background-color: #ffffff;
          padding: 20px;
          display: flex;
          justify-content: flex-start;
          align-content: center;
          align-items: center;
          cursor: pointer;
          &:hover {
            background-color: #eeeeee;
          }
          p {
            margin: 0 0 0 20px;
            font-size: 16px;
            font-weight: $app-font-light;
          }
        }
        ul.accountLocationsList {
          position: relative;
          width: 100%;
          margin: 0;
          padding: 0;
          box-sizing: border-box;
          list-style: none;
          display: flex;
          flex-direction: column;
          li.accountLocationItem {
            position: relative;
            width: 100%;
            margin: 0;
            padding: 10px 5px;
            box-sizing: border-box;
            border-bottom: 1px solid #000000;
            list-style: none;
            display: block;
            ul.accountLocationInfoList {
              position: relative;
              width: 100%;
              margin: 0;
              padding: 0;
              list-style: none;
              display: flex;
              justify-content: space-between;
              @media (max-width: $mobile-condense) {
                flex-wrap: wrap;
              }
              li {
                position: relative;
                display: block;
                margin: 0;
                padding: 0;
                font-size: 1em;
                @media (max-width: $mobile-midbreak) {
                  font-size: 0.9em;
                }
                &.accountLocationInfo-unit {
                  width: 200px;
                  flex-grow: 0;
                  flex-shrink: 0;
                  @media (max-width: $mobile-breakpoint) {
                    width: 160px;
                  }
                  @media (max-width: $mobile-midbreak) {
                    width: 100px;
                  }
                  @media (max-width: $mobile-condense) {
                    width: 100px;
                  }
                }
                &.accountLocationInfo-phone {
                  width: 200px;
                  flex-grow: 0;
                  flex-shrink: 0;
                  @media (max-width: $mobile-breakpoint) {
                    width: 160px;
                  }
                  @media (max-width: $mobile-midbreak) {
                    width: 140px;
                  }
                  @media (max-width: $mobile-condense) {
                    width: 140px;
                  }
                }
                &.accountLocationInfo-address {
                  flex-grow: 1;
                  flex-shrink: 1;
                  @media (max-width: $mobile-condense) {
                    width: 100%;
                    padding: 10px 0;
                  }
                }
                span.accountLocationInfoLabel {
                  font-size: 0.8em;
                  font-weight: $app-font-light;
                }
              }
            }
          }
        }
      }
    }
    &.col-2 {
      justify-content: space-between;
      .accountInfoContainer {
        flex-basis: 45%;
        flex-grow: 0;
        flex-shrink: 0;
      }
      @media (max-width: $mobile-breakpoint) {
        flex-wrap: wrap;
        .accountInfoContainer {
          flex-basis: 100%;
          margin-top: 40px;
          &:first-child {
            margin-top: 0;
          }
        }
      }
    }
  }
  .accountInfoLegal {
    margin: 0;
    font-size: 0.7em;
    font-weight: $app-font-light;
  }
}
