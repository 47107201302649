$max-content-width: 1024px;

$breakpoint-x-large: 1920px;
$breakpoint-large: 1440px;
$breakpoint-min-hd: 1280px;
$breakpoint-tablet: 1024px;
$mobile-breakpoint: 768px;
$mobile-midbreak: 640px;
$mobile-condense: 480px;
$mobile-extra-condense: 380px;

$color-background-dark-gray: #383838;
$color-background-light-gray: #f4f4f4;
$color-background-light-blue: #f7faff;

$color-copy-dark: #383838;
$color-copy-dark-highlight: #666666;
$color-copy-disabled: #999999;
$color-copy-header: #4ca5f8;
$color-active: #4ca5f8;
$color-active-highlight: #4ca5f8;
$color-active2: #ff3826;
$color-copy-status-green: #4f9e3f;

$color-button-dark-background: #383838;
$color-button-dark-highlight: #666666;
$color-button-disable: #bbbab9;
$color-button-primary: #4ca5f8;
$color-button-off: #443344;
$color-button-red: #ff3826;
$color-button-red-transparent: rgba(255, 56, 38, 0.5);

$button-box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);

$color-background-section-number: #ff3826;
$color-copy-section-number: #ffffff;
$color-background-white: #ffffff;

$color-secondary: #fb6509;

$color-size-orange: #ffa52e;
$color-size-orange-light: #fed29f;
$color-size-blue: #4ca5f8;
$color-size-blue-light: #a7d3fa;
$color-size-red: #ff3826;
$color-size-red-light: #fab0a8;

$color-message-success: #12741a;
$color-message-fail: tomato;
