.campaignContinueOverlayContainer {
  display: flex;
  position: fixed;
  left: 50%;
  transform: translateX(-50%);
  bottom: 10px;
  a {
    display: block;
    text-decoration: none;
    padding: 8px 15px;
    font-size: 14px;
    font-weight: $app-font-bold;
    background-color: $color-button-dark-background;
    color: #ffffff;
    border: none;
    border-radius: 100px;
    outline: 0;
    cursor: pointer;
    &:hover {
      background-color: $color-button-dark-highlight;
    }
    margin-right: 10px;
    &:last-child {
      margin-right: 0;
    }
  }
}
