.autoRepeat label.toggle-auto-repeat,
label.toggle-auto-repeat {
  box-sizing: border-box;
  position: relative;
  width: auto;
  &.full {
    width: 100%;
  }
  max-width: 480px;
  height: auto;
  display: flex;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: center;
  padding: 0;
  margin: 0;
  cursor: pointer;
  .autoRepeatLabelIconAndText {
    display: flex;
    align-items: center;
    padding: 0 10px 0 0;
    @media (max-width: $mobile-condense) {
      padding: 0 3px 0 0;
    }
    .autoRepeatLabelIcon {
      position: relative;
      display: block;
      width: 27px;
      height: 27px;
      @media (max-width: $mobile-condense) {
        width: 16px;
        height: 28px;
      }
      fill: $color-button-off;
      &.checked {
        fill: $color-active;
      }
      svg {
        max-width: 100%;
        max-height: 100%;
      }
    }
    .autoRepeatLabelText {
      position: relative;
      display: block;
      font-size: 24px;
      @media (max-width: $mobile-condense) {
        font-size: 16px;
      }
      font-weight: bold;
      margin: 0 5px;
    }
    &.smaller {
      .autoRepeatLabelIcon {
        width: 20px;
        @media (max-width: $mobile-condense) {
          width: 16px;
        }
      }
      .autoRepeatLabelText {
        font-size: 20px;
        font-weight: 600;
        @media (max-width: $mobile-condense) {
          font-size: 14px;
        }
      }
    }
  }
  input {
    position: absolute;
    opacity: 0;
    height: 0;
    width: 0;
  }
}
