.campaignSize {
  ul {
    list-style: none;
    padding: 0;
    margin: 0;
    width: 100%;
    max-width: 480px;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    li {
      box-sizing: border-box;
      width: 100%;
      border-radius: 6px;
      -webkit-backface-visibility: hidden;
      -moz-backface-visibility: hidden;
      -webkit-transform: translate3d(0, 0, 0);
      -moz-transform: translate3d(0, 0, 0);
      position: relative;
      margin: 0 0 25px 0;
      box-shadow: $button-box-shadow;
      .sizeCollapsedView {
        position: relative;
        overflow: hidden;
        -webkit-backface-visibility: hidden;
        -moz-backface-visibility: hidden;
        -webkit-transform: translate3d(0, 0, 0);
        -moz-transform: translate3d(0, 0, 0);
        display: flex;
        justify-content: space-between;
        align-content: center;
        border-radius: 6px;
        cursor: pointer;
        .sizeLabel {
          background-color: $color-button-disable;
          &:nth-child(2n) {
            background-color: $color-size-orange;
          }
          &:nth-child(3n) {
            background-color: $color-size-blue;
          }
          &:nth-child(4n) {
            background-color: $color-size-red;
          }
          color: #ffffff;
          position: relative;
          display: flex;
          flex-direction: column;
          justify-content: center;
          width: 90px;
          @media (max-width: $mobile-condense) {
            width: 80px;
          }
          flex-grow: 0;
          flex-shrink: 0;
          p {
            margin: 0;
            padding: 0 5px;
            font-size: 28px;
            font-weight: $app-font-bold;
            text-align: center;
            @media (max-width: $mobile-condense) {
              font-size: 22px;
            }
          }
        }
        .sizeCollapsedDetails {
          background-color: transparent;
          color: $color-copy-dark;
          position: relative;
          height: 60px;
          display: flex;
          justify-content: space-between;
          align-content: center;
          flex-grow: 1;
          .sizeEstimatedResults {
            flex-grow: 1;
            display: flex;
            flex-direction: column;
            justify-content: center;
            p {
              margin: 0;
              padding: 0 5px 0 20px;
              font-size: 1em;
              .sizeEstimatedResultsLabel {
                font-size: 0.75em;
              }
              font-weight: $app-font-regular;
              text-align: left;
              line-height: 1em;
              @media (max-width: $mobile-condense) {
                font-size: 0.9em;
                line-height: 1em;
                padding: 0 5px 0 10px;
              }
              sup {
                font-size: 0.5em;
              }
              .infoLink {
                display: inline-block;
                width: 8px;
                height: 8px;
                border-radius: 40px;
                background-color: $color-button-dark-background;
              }
            }
          }
          .sizePrice {
            width: 90px;
            @media (max-width: $mobile-condense) {
              width: 70px;
            }
            flex-grow: 0;
            flex-shrink: 0;
            display: flex;
            flex-direction: column;
            justify-content: center;
            p {
              margin: 0;
              padding: 0 15px 0 0;
              font-size: 16px;
              .sizePriceLabel {
                font-size: 12px;
              }
              .sizePriceInfo {
                white-space: nowrap;
              }
              text-align: left;
              line-height: 1.2em;
              @media (max-width: $mobile-condense) {
                font-size: 14px;
              }
            }
          }
          .sizeExpandIcon {
            width: 60px;
            display: flex;
            justify-content: center;
            align-content: center;
            align-items: center;
          }
        }
      }
      &[data-size="Med"] {
        .sizeCollapsedView {
          .sizeLabel {
            background-color: $color-size-orange;
          }
        }
      }
      &[data-size="Lg"] {
        .sizeCollapsedView {
          .sizeLabel {
            background-color: $color-size-blue;
          }
        }
      }
      &[data-size="XL"] {
        .sizeCollapsedView {
          .sizeLabel {
            background-color: $color-size-red;
          }
        }
      }
      .sizeExpandedView {
        position: relative;
        display: flex;
        overflow: hidden;
        flex-wrap: wrap;
        justify-content: flex-start;
        height: auto;
        max-height: 0;
        transition: 0.3s max-height ease-out;
        .sizeDetails {
          display: flex;
          justify-content: center;
          flex-wrap: wrap;
          padding: 15px 5px 15px 5px;
          position: relative;
          box-sizing: border-box;
          margin: 0 auto;
          width: 100%;
          max-width: 480px;
          .sizeDetailsItem {
            position: relative;
            flex-basis: 28%;
            flex-grow: 0;
            border: 3px solid $color-size-red-light;
            &:nth-child(2n) {
              border: 3px solid $color-size-orange-light;
            }
            &:nth-child(3n) {
              border: 3px solid $color-size-blue-light;
            }
            border-radius: 6px;
            margin: 0 6px 12px 6px;
            min-height: 80px;
            @media (max-width: $mobile-condense) {
              flex-basis: 45%;
              margin: 0 4px 8px 4px;
            }
            > p {
              display: block;
              position: relative;
              box-sizing: border-box;
              padding: 15px;
              margin: 0;
              text-align: center;
              font-size: 0.8em;
              font-weight: $app-font-light;
              color: $color-copy-dark;
              > span.emphasis {
                font-size: 2em;
                font-weight: $app-font-bold;
              }
              > span.labelAfter {
                font-size: 0.8em;
              }
              img.iconChannel {
                display: inline-block;
                margin: 0.5em 0.5em 0.2em 0.5em;
                height: 2em;
                width: auto;
              }
            }
          }
        }
        p.sizeChannelsLabel {
          display: block;
          padding: 0 15px 0 25px;
          position: relative;
          box-sizing: border-box;
          margin: 0 auto;
          width: 100%;
          max-width: 480px;
          text-align: left;
          font-weight: $app-font-bold;
          font-size: 1em;
          @media (max-width: $mobile-condense) {
            padding: 0 15px 0 25px;
            font-size: 0.8em;
          }
        }
        .sizeChannels {
          display: flex;
          justify-content: center;
          flex-wrap: wrap;
          padding: 5px 5px 15px 5px;
          position: relative;
          box-sizing: border-box;
          margin: 0 auto;
          width: 100%;
          max-width: 480px;
          .sizeChannelsItem {
            position: relative;
            cursor: pointer;
            flex-basis: 28%;
            flex-grow: 0;
            flex-shrink: 1;
            border: 3px solid #dddddd;
            box-shadow: $button-box-shadow;
            border-radius: 6px;
            margin: 0 6px 12px 6px;
            min-height: 80px;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            @media (max-width: $mobile-condense) {
              flex-basis: 45%;
              margin: 0 4px 8px 4px;
            }
            > p {
              display: block;
              position: relative;
              box-sizing: border-box;
              padding: 15px;
              margin: 0;
              text-align: center;
              font-size: 0.8em;
              font-weight: $app-font-light;
              color: $color-copy-dark;
            }
            .channelFriendlyIcons {
              position: relative;
              display: flex;
              flex-grow: 1;
              justify-content: center;
              align-content: flex-end;
              align-items: flex-end;
              margin-bottom: 15px;
              padding: 0 10px;
              img.iconChannel {
                position: relative;
                display: block;
                box-sizing: border-box;
                max-width: 80px;
                flex-shrink: 1;
                max-height: 2em;
                margin: 0 15px 0 0;
                &:last-child {
                  margin: 0 0 0 0;
                }
                height: auto;
                width: auto;
              }
            }
            button.sizeChannelsItemPreviewButton {
              position: relative;
              cursor: pointer;
              overflow: hidden;
              -webkit-backface-visibility: hidden;
              -moz-backface-visibility: hidden;
              -webkit-transform: translate3d(0, 0, 0);
              -moz-transform: translate3d(0, 0, 0);
              text-align: center;
              margin: 0;
              width: 100%;
              box-sizing: border-box;
              padding: 10px 0;
              background-color: #dddddd;
              font-size: 0.9em;
              border: none;
              border-radius: 0;
              outline: 0;
              display: flex;
              justify-content: center;
              align-content: center;
              align-items: center;
              &.disabled {
                background-color: rgb(189, 189, 189);
                box-shadow: none;
                &:hover {
                  &:after {
                    display: none;
                  }
                }
              }
              &.selected {
                box-shadow: none;
                &:hover {
                  &:after {
                    display: none;
                  }
                }
              }
              p {
                position: relative;
                display: block;
                max-width: 100%;
                margin: 0;
                padding: 0;
                font-size: 1em;
                font-weight: $app-font-bold;
                color: $color-copy-dark;
                text-align: center;
              }
              .iconContainerCircle {
                position: relative;
                margin-left: 10px;
                width: 16px;
                height: 16px;
                background-color: $color-button-dark-background;
                border-radius: 100%;
                display: flex;
                justify-content: center;
                align-content: center;
                align-items: center;
                .icon {
                  position: relative;
                  display: block;
                  margin-left: 1px;
                  width: 0;
                  height: 0;
                  border-top: 3px solid transparent;
                  border-bottom: 3px solid transparent;
                  border-left: 5px solid #ffffff;
                }
              }
            }
            &:hover {
              border: 3px solid rgb(189, 189, 189);
              button.sizeChannelsItemPreviewButton {
                background-color: rgb(189, 189, 189);
              }
            }
          }
        }
        .sizeNextAvailableDate {
          position: relative;
          width: 100%;
          padding: 0 15px;
          margin: 0 0 20px 0;
          display: flex;
          justify-content: center;
          align-content: center;
          align-items: center;
          p.sizeNextAvailableDateLabel {
            position: relative;
            display: block;
            font-size: 0.9em;
            font-weight: $app-font-light;
            color: $color-copy-dark;
            margin: 0 30px 0 0;
            @media (max-width: $mobile-condense) {
              font-size: 0.8em;
              margin: 0 10px 0 0;
            }
            padding: 0;
          }
          p.sizeNextAvailableDateDate {
            position: relative;
            display: block;
            font-size: 0.9em;
            @media (max-width: $mobile-condense) {
              font-size: 0.8em;
            }
            font-weight: $app-font-bold;
            color: $color-copy-dark;
            margin: 0;
            padding: 0;
          }
        }
        .sizeDetailsCopy {
          position: relative;
          width: 100%;
          padding: 0;
          display: flex;
          justify-content: center;
          p {
            font-size: 11px;
            color: $color-copy-dark;
            margin: 0;
            padding: 0 30px;
            @media (max-width: $mobile-condense) {
              padding: 0 15px;
            }
          }
        }
        .sizeSelectContainer {
          position: relative;
          width: 100%;
          padding: 20px 0;
          display: flex;
          justify-content: center;
          button {
            position: relative;
            display: block;
            overflow: hidden;
            -webkit-backface-visibility: hidden;
            -moz-backface-visibility: hidden;
            -webkit-transform: translate3d(0, 0, 0);
            -moz-transform: translate3d(0, 0, 0);
            text-align: center;
            text-decoration: none;
            margin: 20px 0;
            padding: 10px 30px;
            font-size: 14px;
            font-weight: $app-font-bold;
            background-color: $color-button-primary;
            box-shadow: $button-box-shadow;
            color: #ffffff;
            border: none;
            border-radius: 100px;
            outline: 0;
            cursor: pointer;
            &:hover {
              &:after {
                content: "";
                display: block;
                position: absolute;
                left: 0;
                top: 0;
                width: 100%;
                height: 100%;
                background-color: rgba(0, 0, 0, 0.1);
              }
            }
            &.disabled {
              background-color: $color-button-disable;
              box-shadow: none;
              &:hover {
                &:after {
                  display: none;
                }
              }
            }
            &.selected {
              box-shadow: none;
              &:hover {
                &:after {
                  display: none;
                }
              }
            }
          }
        }
      }
      &.expanded {
        &[data-size="Sm"] {
          -webkit-box-shadow: inset 0px 0px 0px 3px $color-button-disable;
          -moz-box-shadow: inset 0px 0px 0px 3px $color-button-disable;
          box-shadow: inset 0px 0px 0px 3px $color-button-disable;
        }
        &[data-size="Med"] {
          -webkit-box-shadow: inset 0px 0px 0px 3px $color-size-orange;
          -moz-box-shadow: inset 0px 0px 0px 3px $color-size-orange;
          box-shadow: inset 0px 0px 0px 3px $color-size-orange;
        }
        &[data-size="Lg"] {
          -webkit-box-shadow: inset 0px 0px 0px 3px $color-size-blue;
          -moz-box-shadow: inset 0px 0px 0px 3px $color-size-blue;
          box-shadow: inset 0px 0px 0px 3px $color-size-blue;
        }
        &[data-size="XL"] {
          -webkit-box-shadow: inset 0px 0px 0px 3px $color-size-red;
          -moz-box-shadow: inset 0px 0px 0px 3px $color-size-red;
          box-shadow: inset 0px 0px 0px 3px $color-size-red;
        }
      }
      .sizeTags {
        position: absolute;
        left: -12px;
        top: -8px;
        display: none;
        img {
          position: relative;
          display: block;
          height: 36px;
          width: auto;
        }
      }
      &[data-size="Med"] {
        .sizeTags {
          display: flex;
        }
      }
    }
  }
  p.campaignSizesInfo {
    position: relative;
    display: block;
    width: 100%;
    max-width: 480px;
    box-sizing: border-box;
    margin: 0;
    padding: 0 15px;
    font-size: 0.8em;
    font-weight: $app-font-bold;
    color: $color-copy-dark;
  }
}
