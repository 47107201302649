.campaignSummaryDetails {
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  h3 {
    font-weight: $app-font-bold;
    color: $color-active;
    font-size: 22px;
    margin: 10px 0;
  }
  .btn-link {
    background: none;
    border: none;
    text-decoration: underline;
    padding-left: 0;
    padding-top: 15px;

    &:hover {
      text-decoration: none;
      cursor: pointer;
    }
    &:focus {
      border: none;
      background: none;
      outline: none;
    }
  }
  .summaryDetailsRow {
    position: relative;
    width: 100%;
    margin: 0;
    padding: 20px 0;
    border-top: 1px solid #c4c4c4;
    &:first-child {
      border-top: none;
    }
    &:last-child {
      border-top: none;
    }
    display: flex;
    justify-content: flex-start;
    .summaryDetailsRowLabel {
      position: relative;
      padding-right: 80px;
      box-sizing: border-box;
      width: 250px;
      @media (max-width: $mobile-breakpoint) {
        width: 130px;
        padding-right: 30px;
        font-size: 0.9em;
        width: 110px;
        padding-right: 20px;
      }
      flex-grow: 0;
      flex-shrink: 0;
      display: flex;
      justify-content: flex-start;
      flex-direction: column;
      p {
        position: relative;
        display: block;
        margin: 0;
        padding: 0;
        text-align: right;
        font-weight: $app-font-semibold;
      }
    }
    .summaryDetailsRowContent {
      position: relative;
      padding: 5px 0 0 0;
      @media (max-width: $mobile-breakpoint) {
        padding: 2px 0 0 0;
      }
      &.summaryDetailsDiscount {
        flex-direction: column;
        .summaryDetailLabel {
          display: inline-block;
          font-weight: $app-font-bold;
          width: 120px;
          @media (max-width: $mobile-breakpoint) {
            width: 100%;
          }
        }
      }
      .summaryDetailsInfo {
        position: relative;
        padding-top: 10px;
      }
      flex-grow: 1;
      flex-shrink: 1;
      display: flex;
      flex-wrap: wrap;
      color: $color-copy-dark;
      font-size: 0.8em;
      p {
        position: relative;
        display: block;
        margin: 0;
        padding: 0;
        text-align: left;
        font-weight: $app-font-regular;
        &.summaryUnitNumber {
          font-weight: $app-font-bold;
          margin-right: 20px;
        }
        &.promotionAndSubcategory {
          flex-basis: 100%;
          font-size: 1em;
          font-weight: $app-font-bold;
          span.separator {
            margin: 0 5px;
          }
        }
        &.offerName {
          flex-basis: 100%;
          font-size: 1.5em;
          margin: 5px 0;
          font-weight: $app-font-bold;
        }
        &.offerDetails {
          flex-basis: 100%;
          font-size: 1em;
          margin: 10px 0;
        }
      }
      .summaryBoxes {
        flex-basis: 100%;
      }
      .campaignSize ul li {
        .sizeCollapsedView {
          cursor: default;
          .sizeCollapsedDetails {
            align-items: center;
            justify-content: flex-end;
            .sizeDetailsEdit {
              position: relative;
              width: 20px;
              height: 20px;
              flex-grow: 0;
              flex-shrink: 0;
              margin-right: 30px;
              display: flex;
              justify-content: center;
              align-content: flex-start;
              align-items: flex-start;
              cursor: pointer;
              img {
                display: block;
                position: relative;
                width: 100%;
                height: auto;
              }
            }
          }
        }
        .sizeExpandedView .sizeChannels .sizeChannelsItem {
          cursor: default;
          &:hover {
            border: 3px solid #dddddd;
          }
          box-shadow: none;
          .sizeChannelsItemPreviewButton {
            display: none;
          }
        }
      }
      .summaryDetailsScheduleRow {
        flex-basis: 100%;
        position: relative;
        margin: 0 0 20px 0;
        &:last-child {
          margin: 0;
        }
        padding: 0;
        display: flex;
        justify-content: flex-start;
        .summaryDetailsScheduleLabel {
          font-size: 1em;
          width: 120px;
          @media (max-width: $mobile-breakpoint) {
            font-size: 0.9em;
            width: 80px;
          }
          flex-grow: 0;
          flex-shrink: 0;
          font-weight: $app-font-bold;
          color: $color-copy-dark;
        }
        .summaryDetailsScheduleInfo {
          font-size: 1em;
          flex-grow: 0;
          flex-shrink: 0;
          width: 160px;
          margin: 0 0 20px 0;
          @media (max-width: $mobile-breakpoint) {
            font-size: 0.9em;
            width: 70px;
            margin: 0 10px;
          }
          text-align: left;
          font-weight: $app-font-light;
          color: $color-copy-dark;
        }
        .summaryDetailsScheduleEdit {
          position: relative;
          width: 20px;
          height: 20px;
          flex-grow: 0;
          flex-shrink: 0;
          display: flex;
          justify-content: center;
          align-content: flex-start;
          align-items: flex-start;
          cursor: pointer;
          img {
            display: block;
            position: relative;
            width: 100%;
            height: auto;
          }
        }
        &.autoRepeat {
          align-items: center;
          p {
            font-size: 12px;
            margin-left: 10px;
          }
          @media (max-width: $mobile-breakpoint) {
            flex-wrap: wrap;
            p {
              width: 100%;
              margin-left: 0;
              margin-top: 3px;
            }
          }
        }
      }
      .paymentDefault {
        position: relative;
        width: auto;
        max-width: 400px;
        cursor: pointer;
        display: flex;
        justify-content: flex-start;
        align-content: center;
        align-items: center;
        img.paymentLogo {
          height: 24px;
          width: auto;
        }
        p.cardNumber {
          font-size: 1em;
          font-weight: $app-font-regular;
          color: $color-copy-dark;
          margin: 0 47px 0 45px;
        }
        .paymentDefaultEdit {
          position: relative;
          width: 20px;
          height: 20px;
          flex-grow: 0;
          flex-shrink: 0;
          display: flex;
          justify-content: center;
          align-content: flex-start;
          align-items: flex-start;
          img {
            display: block;
            position: relative;
            width: 100%;
            height: auto;
          }
        }
      }
      .paymentSelection {
        position: relative;
        width: 100%;
        max-width: 400px;
        box-sizing: border-box;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        ul.paymentOptionsList {
          width: 100%;
          padding: 0;
          margin: 0;
          position: relative;
          display: block;
          li.paymentOption {
            margin: 0 0 10px 0;
            box-sizing: border-box;
            width: 100%;
            border-radius: 6px;
            overflow: hidden;
            -webkit-backface-visibility: hidden;
            -moz-backface-visibility: hidden;
            -webkit-transform: translate3d(0, 0, 0);
            -moz-transform: translate3d(0, 0, 0);
            position: relative;
            display: block;
            box-shadow: $button-box-shadow;
            background-color: #ffffff;
            label {
              box-sizing: border-box;
              position: relative;
              display: flex;
              padding: 0;
              width: 100%;
              height: 100%;
              cursor: pointer;
              input {
                position: absolute;
                opacity: 0;
                height: 0;
                width: 0;
              }
              .checkmark {
                position: absolute;
                box-sizing: border-box;
                top: 10px;
                right: 10px;
                height: 30px;
                width: 30px;
                /*background-color:#FFFFFF;
                                border:1px solid #CCCCCC;*/
                background-color: transparent;
                border: none;
                border-radius: 50%;
                &:after {
                  content: "";
                  position: absolute;
                  display: none;
                  left: 9px;
                  top: 4px;
                  width: 8px;
                  height: 14px;
                  border: solid white;
                  border-width: 0 3px 3px 0;
                  -webkit-transform: rotate(45deg);
                  -ms-transform: rotate(45deg);
                  transform: rotate(45deg);
                }
                &.checked {
                  background-color: $color-active;
                  border: none;
                  &:after {
                    display: block;
                  }
                }
              }
              @media (max-width: $mobile-breakpoint) {
                .checkmark {
                  position: absolute;
                  box-sizing: border-box;
                  top: 6px;
                  right: 6px;
                  height: 24px;
                  width: 24px;
                  &:after {
                    left: 8px;
                    top: 3px;
                    width: 6px;
                    height: 12px;
                    border-width: 0 3px 3px 0;
                  }
                }
              }
              .paymentOptionDetails {
                flex-basis: 50%;
                flex-shrink: 0;
                flex-grow: 1;
                display: block;
                position: relative;
                padding: 15px;
                box-sizing: border-box;
                img {
                  height: 24px;
                  width: auto;
                }
                p.cardNumber {
                  margin: 10px 0 0 0;
                  font-size: 14px;
                  font-weight: $app-font-light;
                  white-space: nowrap;
                }
              }
              .paymentOptionLogoBackground {
                flex-basis: 50%;
                flex-shrink: 1;
                flex-grow: 0;
                display: block;
                position: relative;
                padding: 0;
                img {
                  display: block;
                  position: absolute;
                  bottom: -4px;
                  right: 5px;
                  width: auto;
                  height: auto;
                  min-width: 50%;
                  min-height: 40%;
                  max-width: 100%;
                  max-height: 70%;
                  opacity: 0.15;
                  &.mastercard {
                    bottom: 0;
                    transform: translateY(40%);
                    width: 140px;
                    max-height: 200%;
                  }
                }
              }
            }
            &.selected {
              box-shadow: none;
              -webkit-box-shadow: inset 0px 0px 0px 3px $color-active;
              -moz-box-shadow: inset 0px 0px 0px 3px $color-active;
              box-shadow: inset 0px 0px 0px 3px $color-active;
            }
          }
        }
        .paymentOptionAddCard {
          margin: 0;
          box-sizing: border-box;
          width: 100%;
          border-radius: 6px;
          overflow: hidden;
          -webkit-backface-visibility: hidden;
          -moz-backface-visibility: hidden;
          -webkit-transform: translate3d(0, 0, 0);
          -moz-transform: translate3d(0, 0, 0);
          position: relative;
          box-shadow: $button-box-shadow;
          background-color: #ffffff;
          padding: 20px;
          display: flex;
          justify-content: flex-start;
          align-content: center;
          align-items: center;
          cursor: pointer;
          &:hover {
            background-color: #eeeeee;
          }
          p {
            margin: 0 0 0 20px;
            font-size: 16px;
            font-weight: $app-font-light;
          }
        }
      }
      .paymentDisclaimer {
        margin: 10px 0 0 0;
        box-sizing: border-box;
        width: 100%;
        max-width: 480px;
        position: relative;
        label {
          position: relative;
          display: block;
          width: 100%;
          max-width: 640px;
          box-sizing: border-box;
          padding: 0 0 0 40px;
          font-size: 14px;
          cursor: pointer;
          @media (max-width: $mobile-breakpoint) {
            font-size: 12px;
          }
          cursor: pointer;
          input {
            position: absolute;
            opacity: 0;
            height: 0;
            width: 0;
          }
          .checkmark {
            position: absolute;
            top: 0;
            left: 0;
            height: 20px;
            width: 20px;
            background-color: #ffffff;
            border: 2px solid $color-button-dark-background;
            border-radius: 4px;
            &:after {
              content: "";
              position: absolute;
              display: none;
              left: 6px;
              top: 2px;
              width: 5px;
              height: 10px;
              border: solid white;
              border-width: 0 3px 3px 0;
              -webkit-transform: rotate(45deg);
              -ms-transform: rotate(45deg);
              transform: rotate(45deg);
            }
            &.checked {
              background-color: $color-active;
              &:after {
                display: block;
              }
            }
          }
        }
      }
    }
    &.schedule {
      @media (max-width: $mobile-breakpoint) {
        flex-wrap: wrap;
        .summaryDetailsRowLabel {
          width: 100%;
          padding-right: 0;
          p {
            text-align: left;
          }
        }
      }
    }
    &.payment,
    &.promoCode {
      @media (max-width: $mobile-breakpoint) {
        flex-wrap: wrap;
        .summaryDetailsRowLabel {
          width: 100%;
          padding-right: 0;
          p {
            text-align: left;
            margin: 20px 0;
          }
        }
        .summaryDetailsRowContent {
          width: 100%;
          flex-grow: 0;
          flex-shrink: 0;
        }
      }
    }
    &.total {
      align-content: flex-start;
      align-items: flex-start;
      background-color: #f4f4f4;
      border-top: none;
      .summaryDetailsRowContent {
        padding: 0 10px 0 0;
        max-width: 480px;
      }
      .summaryDetailsPrice {
        position: relative;
        width: 100%;
        padding: 0;
        color: $color-copy-dark;
        font-size: 1.7em;
        font-weight: $app-font-bold;
        text-align: right;
        span {
          display: inline-block;
          margin-left: 10px;
          font-size: 0.6em;
          font-weight: $app-font-light;
        }
      }
    }
    &.mobileWrap {
      @media (max-width: $mobile-breakpoint) {
        flex-wrap: wrap;
        .summaryDetailsRowLabel {
          width: 100%;
          padding-right: 0;
          p {
            text-align: left;
            margin: 20px 0;
          }
        }
        .summaryDetailsRowContent {
          width: 100%;
          flex-grow: 0;
          flex-shrink: 0;
        }
      }
    }
  }
}
