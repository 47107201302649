.rc-tooltip {
  .rc-tooltip-inner {
    border-radius: 10px;
    background-color: $color-button-primary;
    max-width: 165px;
  }
  &.rc-tooltip-placement-left .rc-tooltip-arrow {
    border-left-color: $color-button-primary;
  }
  &.rc-tooltip-placement-right .rc-tooltip-arrow {
    border-right-color: $color-button-primary;
  }
  &.rc-tooltip-placement-top .rc-tooltip-arrow {
    border-top-color: $color-button-primary;
  }
  &.rc-tooltip-placement-bottom .rc-tooltip-arrow {
    border-bottom-color: $color-button-primary;
  }
}
.tooltipWarningIcon {
  background: $color-button-primary;
  border-radius: 50%;
  color: white;
  height: 20px;
  margin: 0 15px;
  padding-bottom: 3px;
  text-align: center;
  width: 23px;
}
