@import "./assets/styles/variables";
@import "./assets/styles/globals";

@import "./containers/login/Login.scss";
@import "./containers/activate/ActivateCustomerUser.scss";
@import "./containers/account/Account.scss";
@import "./containers/alerts/Alerts.scss";
@import "./containers/contact/Contact.scss";
@import "./containers/landing-pages/DigitalMedia.scss";
@import "./containers/forgot-password/ForgotPassword.scss";
@import "./containers/home/Home.scss";
@import "./containers/how-it-works/HowItWorks.scss";
@import "./containers/start/Start.scss";
@import "./containers/thank-you/ThankYou.scss";
@import "./containers/status/Status.scss";
@import "./containers/reset-password/ResetPassword.scss";
@import "./containers/legal/styles.scss";
@import "./containers/individual-campaign/IndividualCampaign.module.scss";

@import "./components/add-card/AddCard.scss";
@import "./components/edit-card/EditCard.scss";
@import "./components/input/Input.module.scss";
@import "./components/header/Header.scss";
@import "./components/footer/Footer.scss";
@import "./components/unit-selector/UnitSelector.scss";
@import "./components/location-nav/LocationNav.scss";
@import "./components/global-subnav/GlobalSubnav.scss";
@import "./components/reporting/reporting.scss";

@import "./components/campaign-select/CampaignSelect.scss";
@import "./components/datepicker-custom-input/DatePickerCustomInput.scss";
@import "./components/campaign-continue-overlay/CampaignContinueOverlay.scss";
@import "./components/payment-options-list/PaymentOptionsList.scss";
@import "./components/toggle/Toggle.scss";
@import "./components/toggle-auto-repeat/ToggleAutoRepeat.scss";
@import "./components/select-input/SelectInput.scss";
@import "./components/tooltip/Tooltip.scss";
@import "./components/loader/Loader.scss";
@import "./hooks/useConfirmationModal.scss";

.appContainer {
  overflow-x: hidden;
  position: relative;
  display: block;
  margin: 0 auto;
  width: 100%;
  max-width: 1440px;
  background-color: #ffffff;
  // If there is noMaintenanceNotification displayed, add the top margins.
  main.noMaintenanceNotification {
    margin-top: 80px;
    @media (max-width: $mobile-breakpoint) {
      margin-top: 60px;
    }
  }
}
