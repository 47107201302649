.campaignGetStarted {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 100px 0 60px 0;
  padding: 0 15px;
  @media (max-width: $mobile-breakpoint) {
    padding: 0;
    margin: 50px 0;
  }
  @media (max-width: $mobile-condense) {
    margin: 30px 0;
  }
  h1 {
    text-align: center;
    width: 100%;
    margin: 0;
    font-size: 42px;
    @media (max-width: $mobile-breakpoint) {
      font-size: 36px;
    }
    @media (max-width: $mobile-condense) {
      font-size: 32px;
    }
    line-height: 1.3em;
    color: $color-copy-dark;
    .name {
      color: $color-active;
    }
    .punctuation {
      color: $color-active;
    }
  }
  p.subheading {
    text-align: center;
    width: 100%;
    max-width: 100%;
    margin: 15px 0 0 0;
    font-size: 1.5em;
    font-weight: $app-font-semibold;
    @media (max-width: $mobile-breakpoint) {
      font-size: 1.2em;
    }
    @media (max-width: $mobile-condense) {
      font-size: 1em;
    }
  }
  p.instructions {
    text-align: center;
    width: 100%;
    max-width: 100%;
    margin: 15px 0 0 0;
    font-size: 1.1em;
    font-weight: $app-font-regular;
    @media (max-width: $mobile-breakpoint) {
      font-size: 0.9em;
    }
    @media (max-width: $mobile-condense) {
      font-size: 0.8em;
    }
  }
  .iconScrollDown {
    position: relative;
    margin: 40px auto 20px auto;
    width: 50px;
    height: 50px;
    display: block;
    cursor: pointer;
    &:hover {
      .chevron {
        &:before {
          border-color: $color-active-highlight;
        }
      }
    }
    .chevron {
      position: relative;
      margin: 0 auto;
      top: 10px;
      width: 0.9em;
      height: 0.8em;
      font-size: 2em;
      transform: scale3d(1, 1, 1);
      transform-origin: 50% 50%;
      animation-name: scrollDownPulse;
      animation-duration: 3s;
      animation-timing-function: ease-in-out;
      animation-iteration-count: infinite;
      &:before {
        transition: 0.3s border-color;
        border-color: $color-active;
        border-style: solid;
        border-width: 0.13em 0.13em 0 0;
        content: "";
        display: inline-block;
        height: 0.45em;
        left: 0.15em;
        position: relative;
        top: 0;
        transform: rotate(135deg);
        vertical-align: top;
        width: 0.45em;
      }
    }
    @keyframes scrollDownPulse {
      0% {
        transform: scale3d(1, 1, 1);
      }
      50% {
        transform: scale3d(1.5, 1.5, 1.5);
      }
      100% {
        transform: scale3d(1, 1, 1);
      }
    }
  }
  button {
    padding: 8px 15px;
    font-size: 14px;
    font-weight: $app-font-bold;
    background-color: $color-button-dark-background;
    color: #ffffff;
    border: none;
    border-radius: 100px;
    outline: 0;
    cursor: pointer;
    &:hover {
      background-color: $color-button-dark-highlight;
    }
  }
}
