.campaignContinue {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 40px 0 40px 0;
  h1 {
    width: 100%;
    text-align: left;
    margin: 0;
  }
  p {
    width: 100%;
    text-align: left;
    margin: 0 0 10px 0;
  }
  .buttonsContainer {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    button {
      padding: 8px 15px;
      font-size: 14px;
      font-weight: $app-font-bold;
      background-color: $color-button-dark-background;
      color: #ffffff;
      border: none;
      border-radius: 100px;
      outline: 0;
      cursor: pointer;
      &:hover {
        background-color: $color-button-dark-highlight;
      }
      margin-right: 15px;
      &:last-child {
        margin-right: 0;
      }
    }
  }
}
