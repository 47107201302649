.editCardFormContainer {
  padding: 15px;
}
.editCardField {
  display: block;
  width: 100%;
}
button.cancel-button {
  margin-left: 10px;
}

p.update-note {
  font-style: italic;
  font-size: 12px;
}
