.addCard {
  position: relative;
  width: 100%;
  max-width: 480px;
  border: none;
  box-sizing: border-box;
  margin: 10px 0;
  text-align: center;

  .cardField {
    outline: unset;
    box-sizing: border-box;
    border: solid $color-active;
    padding: 9px 4px;
    border-radius: 5px;
    &.hasError {
      border: solid $color-size-red;
    }
  }

  p {
    margin: 0 15px 0 0;
    &.error {
      color: tomato;
    }
  }
  button {
    background: $color-button-primary;
    padding: 10px 30px;
    font-size: 14px;
    font-weight: $app-font-bold;
    box-shadow: $button-box-shadow;
    color: #ffffff;
    border-radius: 100px;
    border: none;
    position: relative;
    outline: 0;
    cursor: pointer;
    margin: 10px 0 auto auto;
    text-align: center;
    &.disabled {
      background: $color-button-disable;
      cursor: default;
      &:after {
        background-color: unset;
      }
    }
    &:after {
      content: "";
      display: none;
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      background-color: rgba(0, 0, 0, 0.1);
      border-radius: 100px;
    }
    &:hover {
      &:after {
        display: block;
      }
    }
  }
}
