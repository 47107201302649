ul.paymentOptionsList {
  width: 100%;
  padding: 0;
  margin: 0;
  position: relative;
  display: block;
  li.paymentOption {
    -webkit-user-select: none; /* Safari */
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* IE10+/Edge */
    user-select: none; /* Standard */
    margin: 0 0 10px 0;
    box-sizing: border-box;
    width: 100%;
    max-width: 480px;
    border-radius: 6px;
    overflow: hidden;
    -webkit-backface-visibility: hidden;
    -moz-backface-visibility: hidden;
    -webkit-transform: translate3d(0, 0, 0);
    -moz-transform: translate3d(0, 0, 0);
    position: relative;
    display: block;
    box-shadow: $button-box-shadow;
    background-color: #ffffff;
    &:before {
      content: "Setting to Default...";
      font-family: $global-font-family;
      font-size: 13px;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      display: none;
    }
    &:after {
      display: none;
      content: "";
      height: 100%;
      width: 100%;
      background: rgba($color: #000000, $alpha: 0.2);
      position: absolute;
      top: 0;
      left: 0;
    }
    &.isSwitchingDefault {
      &:before {
        display: block;
      }
    }
    &.isCardDeleting {
      &:after {
        display: block;
      }
    }
    .paymentOptionDeleteConfirmation {
      font-size: 15px;
      color: black;
      li {
        margin-right: 10px;
        &:nth-child(2) {
          margin-right: 0;
        }
        button {
          color: $color-active;
        }
      }
    }
    ul {
      z-index: 1;
      position: absolute;
      top: 5px;
      right: 0;

      li {
        float: left;
        list-style-type: none;
        button {
          color: $color-button-primary;
          font-size: 15px;
          background: transparent;
          border: none;
          cursor: pointer;
          &.disabled {
            color: $color-button-disable;
            &:hover {
              text-decoration: none;
              cursor: default;
            }
          }
          &:hover {
            text-decoration: underline;
          }
        }
        &:nth-child(3) {
          button {
            color: $color-active2;
          }
        }
      }
    }
    label {
      box-sizing: border-box;
      position: relative;
      display: flex;
      padding: 0;
      width: 100%;
      height: 100%;

      input {
        position: absolute;
        opacity: 0;
        height: 0;
        width: 0;
      }
      .defaultEdit {
        position: absolute;
        box-sizing: border-box;
        top: 15px;
        right: 115px;
        // height: 24px;
        // width: 24px;
      }
      .checkmark {
        position: absolute;
        box-sizing: border-box;
        top: 10px;
        right: 10px;
        height: 30px;
        width: 30px;
        /*background-color:#FFFFFF;
        border:1px solid #CCCCCC;*/
        background-color: transparent;
        border: none;
        border-radius: 50%;
        &:after {
          content: "";
          position: absolute;
          display: none;
          left: 9px;
          top: 4px;
          width: 8px;
          height: 14px;
          border: solid white;
          border-width: 0 3px 3px 0;
          -webkit-transform: rotate(45deg);
          -ms-transform: rotate(45deg);
          transform: rotate(45deg);
        }
        &:before {
          content: "Default";
          font-size: 16px;
          position: absolute;
          color: $color-button-primary;
          left: -68px;
          top: 3px;
          display: none;
        }
        &.checked {
          background-color: $color-active;
          border: none;
          &:after,
          &:before {
            display: block;
          }
        }
      }

      @media (max-width: $mobile-condense) {
        .checkmark {
          position: absolute;
          box-sizing: border-box;
          top: 6px;
          right: 6px;
          height: 24px;
          width: 24px;
          &:after {
            left: 8px;
            top: 3px;
            width: 6px;
            height: 12px;
            border-width: 0 3px 3px 0;
          }
        }
      }
      .paymentOptionDetails {
        flex-basis: 50%;
        flex-shrink: 0;
        flex-grow: 1;
        display: block;
        position: relative;
        padding: 15px;
        box-sizing: border-box;
        img {
          height: 24px;
          width: auto;
        }
        p.cardNumber {
          margin: 10px 0 0 0;
          font-size: 14px;
          font-weight: $app-font-light;
          white-space: nowrap;
        }
      }
      .paymentOptionLogoBackground {
        flex-basis: 50%;
        flex-shrink: 1;
        flex-grow: 0;
        display: block;
        position: relative;
        padding: 0;
        img {
          display: block;
          position: absolute;
          bottom: -4px;
          right: 5px;
          width: auto;
          height: auto;
          min-width: 50%;
          min-height: 40%;
          max-width: 100%;
          max-height: 70%;
          opacity: 0.15;
          &.mastercard {
            bottom: 0;
            transform: translateY(40%);
            width: 140px;
            max-height: 200%;
          }
        }
      }
    }
    &.selected {
      box-shadow: none;
      -webkit-box-shadow: inset 0px 0px 0px 3px $color-active;
      -moz-box-shadow: inset 0px 0px 0px 3px $color-active;
      box-shadow: inset 0px 0px 0px 3px $color-active;
      ul {
        margin-top: 7px;
        li:nth-last-child(1) {
          margin-left: 5px;
          margin-right: 15px;
        }
        li:nth-last-child(2) {
          margin-top: 2px;
        }
      }
    }
  }
}
