.contentActivateCustomerUser {
  position: relative;
  margin: 156px auto 0 auto;
  padding: 30px;
  display: block;
  width: 100%;
  box-sizing: border-box;
  max-width: 480px;
  background-color: #ffffff;
  h1 {
    margin: 0 0 20px 0;
  }
  .activateCustomerUserForm {
    position: relative;
    margin: 0;
    width: auto;
    height: auto;
    display: flex;
    flex-direction: column;
    .activateCustomerUserFormRow {
      position: relative;
      margin: 10px 0;
      display: flex;
      label {
        display: flex;
        width: 100%;
        justify-content: flex-start;
        align-content: flex-start;
        align-items: center;
        flex-wrap: wrap;
        p {
          display: block;
          margin: 0;
          padding: 0;
          width: 100%;
        }
        input {
          display: block;
          width: 100%;
          box-sizing: border-box;
          border-radius: 8px;
          border: none;
          background-color: #ffffff;
          border: 1px solid #cccccc;
          font-family: $global-font-family;
          color: $color-copy-dark;
          padding: 10px;
          font-size: 1em;
        }
      }

      .userAgreement {
        margin: 10px 0 0 0;
        box-sizing: border-box;
        width: 100%;
        position: relative;
        label {
          position: relative;
          display: block;
          width: 100%;
          max-width: 640px;
          box-sizing: border-box;
          padding: 0 0 0 40px;
          font-size: 14px;
          cursor: pointer;
          @media (max-width: $mobile-condense) {
            font-size: 12px;
          }
          cursor: pointer;
          input {
            position: absolute;
            opacity: 0;
            height: 0;
            width: 0;
          }
          .checkmark {
            position: absolute;
            top: 0;
            left: 0;
            height: 20px;
            width: 20px;
            background-color: #ffffff;
            border: 2px solid $color-button-dark-background;
            border-radius: 4px;
            &:after {
              content: "";
              position: absolute;
              display: none;
              left: 6px;
              top: 2px;
              width: 5px;
              height: 10px;
              border: solid white;
              border-width: 0 3px 3px 0;
              -webkit-transform: rotate(45deg);
              -ms-transform: rotate(45deg);
              transform: rotate(45deg);
            }
            &.checked {
              background-color: $color-active;
              &:after {
                display: block;
              }
            }
          }
        }
      }

      &.submit {
        justify-content: flex-end;
        button.activateCustomerUserFormButtonSubmit {
          align-self: flex-end;
          position: relative;
          display: block;
          overflow: hidden;
          -webkit-backface-visibility: hidden;
          -moz-backface-visibility: hidden;
          -webkit-transform: translate3d(0, 0, 0);
          -moz-transform: translate3d(0, 0, 0);
          text-align: center;
          text-decoration: none;
          width: 160px;
          margin: 10px 0;
          min-width: 160px;
          flex-grow: 0;
          padding: 10px 30px;
          font-size: 14px;
          font-weight: $app-font-bold;
          background-color: $color-button-primary;
          box-shadow: $button-box-shadow;
          color: #ffffff;
          border: none;
          border-radius: 100px;
          outline: 0;
          cursor: pointer;
          &:hover {
            &:after {
              content: "";
              display: block;
              position: absolute;
              left: 0;
              top: 0;
              width: 100%;
              height: 100%;
              background-color: rgba(0, 0, 0, 0.1);
            }
          }
          &.disabled {
            background-color: $color-button-disable;
            box-shadow: none;
          }
          @media (max-width: $mobile-breakpoint) {
            background-color: #ffffff;
            color: $color-copy-dark;
            min-width: 120px;
          }
        }
      }
    }
  }
}
