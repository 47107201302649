.toggle {
  position: relative;
  display: block;
  overflow: hidden;
  height: auto;
  width: 124px;
  &.smaller {
    width: 86px;
  }
  @media (max-width: $mobile-breakpoint) {
    width: 98px;
  }
  @media (max-width: $mobile-condense) {
    width: 86px;
    &.smaller {
      width: 65px;
    }
  }
  &:before {
    content: "";
    position: relative;
    display: block;
    width: 100%;
    padding-bottom: 40%;
  }
  background-color: $color-button-off;
  border-radius: 100px;
  border: none;
  color: #ffffff;
  font-size: 20px;
  &.smaller {
    font-size: 18px;
  }
  @media (max-width: $mobile-breakpoint) {
    font-size: 18px;
  }
  @media (max-width: $mobile-condense) {
    &.smaller {
      font-size: 14px;
    }
  }
  font-weight: bold;
  .toggleLabel {
    display: block;
    width: 60%;
    position: absolute;
    left: 20%;
    top: 50%;
    transform: translateY(-50%);
    &.on {
      display: none;
      text-align: left;
    }
    &.off {
      text-align: right;
    }
  }
  .toggleIndicator {
    position: absolute;
    transform: translateX(0%);
    transition: transform 0.25s ease-out;
    left: 6%;
    top: 9.5%;
    display: block;
    background-color: #ffffff;
    border-radius: 50%;
    width: 32%;
    height: auto;
    &:before {
      content: "";
      position: relative;
      display: block;
      width: 100%;
      padding-bottom: 100%;
    }
  }
  &.on {
    left: none;
    background-color: $color-active;
    border: none;
    .toggleLabel {
      &.on {
        display: block;
      }
      &.off {
        display: none;
      }
    }
    .toggleIndicator {
      transform: translateX(178%);
    }
  }
  &.enabled {
    opacity: 50%;
  }
}
