.campaignSelectContainer {
  position: relative;
  width: 100%;
  max-width: 1040px;
  margin: 0 auto;
  h1 {
    text-align: left;
  }
  p {
    text-align: left;
  }
  .campaignSelectSection {
    position: relative;
    display: block;
    padding: 80px 0;
    &.campaignSummarySectionRef {
      padding: 80px 0 20px 0;
    }
    &.campaignLaunch {
      padding: 0;
    }
    transition: opacity 0.3s;
    opacity: 0.2;
    &.active {
      opacity: 1;
    }
    &.nofade {
      opacity: 1;
    }
    .side-by-side {
      display: flex;
      align-items: flex-start;
      @media (max-width: $mobile-breakpoint) {
        flex-wrap: wrap;
      }
      padding: 0 30px;
      @media (max-width: $mobile-condense) {
        padding: 0 15px;
      }
    }
    .campaignReviewContentContainer {
      padding: 0 30px;
      @media (max-width: $mobile-condense) {
        padding: 0 15px;
      }
      .campaignSectionHeader {
        @media (max-width: $mobile-breakpoint) {
          padding-top: 0;
          padding: 0;
        }
      }
    }
  }
  .campaignSelectContentInner {
    display: block;
    padding: 0 30px;
    @media (max-width: $mobile-condense) {
      padding: 0 15px;
    }
  }
}
.fadeInWrapper {
  opacity: 0;
  transition: opacity 1s;
  &.enabled {
    opacity: 1;
  }
}
.contentWrapperHorizontalScroll {
  width: 100%;
  max-width: 1040px;
  margin: 0 auto;
}
.nextSectionPreview {
  margin: 80px 0 40px 0;
  font-size: 14px;
  font-weight: $app-font-bold;
  color: #999999;
  border-top: 3px solid #999999;
}

@import "./components/campaign-select/campaign-promotion/CampaignPromotion.scss";
@import "./components/campaign-select/campaign-get-started/CampaignGetStarted.scss";
@import "./components/campaign-select/campaign-continue/CampaignContinue.scss";
@import "./components/campaign-select/campaign-offer/CampaignOffer.scss";
@import "./components/campaign-select/campaign-schedule/CampaignSchedule.scss";
@import "./components/campaign-select/campaign-section-header/CampaignSectionHeader.scss";
@import "./components/campaign-select/campaign-size/CampaignSize.scss";
@import "./components/campaign-select/campaign-size-preview/CampaignSizePreview.scss";
@import "./components/campaign-select/campaign-subcategory/CampaignSubCategory.scss";
@import "./components/campaign-select/campaign-summary-details/CampaignSummaryDetails.scss";
@import "./components/campaign-select/campaign-launch-button/CampaignLaunchButton.scss";
@import "./components/campaign-select/campaign-launch-overlay/CampaignLaunchOverlay.scss";
@import "./components/campaign-select/campaign-next-prev/CampaignNextPrev.scss";
@import "./components/campaign-select/campaign-next-section-message/CampaignNextSectionMessage.scss";
@import "./components/campaign-select/campaign-select-status/CampaignSelectStatus.scss";
@import "./components/campaign-select/check-discount/Discount.scss";
