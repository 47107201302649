.alertsContainer {
  h1 {
    text-align: center;
  }
  .startButton {
    position: relative;
    display: block;
    overflow: hidden;
    backface-visibility: hidden;
    transform: translate3d(0, 0, 0);
    text-align: center;
    text-decoration: none;
    margin: 20px 0;
    width: fit-content;
    padding: 10px 30px;
    font-size: 14px;
    font-weight: $app-font-bold;
    background-color: $color-button-primary;
    box-shadow: $button-box-shadow;
    color: #ffffff;
    border: none;
    border-radius: 100px;
    outline: 0;
    cursor: pointer;
    &:hover {
      &:after {
        content: "";
        display: block;
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.1);
      }
    }
  }
  .alertsIntro {
    position: relative;
    width: 100%;
    display: flex;
    @media (max-width: $mobile-condense) {
      flex-wrap: wrap;
    }
    align-content: center;
    align-items: center;
    padding: 80px 0;
    h1.alertsIntroHeading {
      font-size: 32px;
      font-weight: $app-font-bold;
      text-align: left;
      line-height: 1.2em;
      margin: 0 80px 0 0;
      @media (max-width: 850px) {
        font-size: 28px;
        margin: 0 40px 0 0;
      }
      @media (max-width: $mobile-condense) {
        width: 100%;
        margin: 0 0 20px 0;
      }
      span.part1 {
        color: $color-copy-dark;
      }
      span.part2 {
        color: $color-copy-dark;
      }
    }
    p.alertsIntroParagraph {
      font-size: 1em;
      max-width: 640px;
      @media (max-width: 850px) {
        font-size: 0.8em;
      }
      color: $color-copy-dark;
      position: relative;
      margin: 0;
    }
  }
  .alertItemContainer {
    position: relative;
    width: 100%;
    display: flex;
    align-content: flex-start;
    align-items: flex-start;
    padding: 40px 0 80px 0;
    @media (max-width: $mobile-breakpoint) {
      flex-wrap: wrap;
    }
    .alertItemContentLeft {
      position: relative;
      display: flex;
      flex-wrap: wrap;
      flex-grow: 0;
      flex-shrink: 0;
      width: 300px;
      margin: 0 40px 0 0;
      @media (max-width: $mobile-breakpoint) {
        width: 100%;
      }
      h1.alertItemHeading {
        position: relative;
        display: block;
        width: 100%;
        font-size: 32px;
        font-weight: $app-font-bold;
        text-align: left;
        line-height: 1.2em;
        margin: 0 0 20px 0;
        @media (max-width: 850px) {
          font-size: 28px;
        }
        span.part1 {
          color: $color-copy-dark;
        }
        span.part2 {
          color: $color-copy-dark;
        }
      }
      p.alertItemParagraph {
        font-size: 1em;
        @media (max-width: 850px) {
          font-size: 0.8em;
        }
        color: $color-copy-dark;
        position: relative;
        margin: 0;
      }
      .alertItemWeatherContainer {
        position: relative;
        width: 100%;
        margin-top: 10px;
        display: flex;
        flex-wrap: wrap;
        h4.alertItemWeatherHeading {
          position: relative;
          display: block;
          font-size: 1em;
          font-weight: $app-font-bold;
          text-align: left;
          line-height: 1.6em;
          margin: 0;
          color: $color-copy-dark;
          span.part1 {
            font-size: 0.9em;
            font-weight: $app-font-regular;
          }
          span.part2 {
            font-size: 1.2em;
            font-weight: $app-font-bold;
          }
        }
        .alertItemWeatherForecast {
          position: relative;
          width: 100%;
          margin-top: 20px;
          display: flex;
          align-items: center;
          align-content: center;
          img.weatherIcon {
            display: block;
            width: auto;
            height: 100%;
            max-height: 75px;
            flex-grow: 0;
            flex-shrink: 0;
          }
          .weatherDetails {
            position: relative;
            margin: 0 0 0 20px;
            display: flex;
            flex-direction: column;
            p.conditions {
              margin: 0;
              text-align: left;
              font-size: 0.7em;
            }
            .temperature {
              display: flex;
              align-content: center;
              align-items: center;
              p {
                margin: 0;
                font-size: 2em;
                font-weight: $app-font-bold;
                color: $color-copy-dark;
                sup {
                  display: inline-block;
                  font-size: 0.4em;
                  font-weight: $app-font-regular;
                  padding-top: 0.1em;
                }
              }
              .dividerSlant {
                display: block;
                width: 2px;
                height: 2em;
                margin: 0 10px;
                background-color: $color-copy-dark;
                transform: rotateZ(15deg);
              }
            }
            a {
              display: block;
              font-size: 0.5em;
              color: $color-copy-dark;
            }
          }
        }
      }
    }
    .alertItemContentRight {
      position: relative;
      display: flex;
      flex-wrap: wrap;
      flex-grow: 1;
      flex-shrink: 1;
      .alertItemOffersContainer {
        position: relative;
        width: 100%;
        display: flex;
      }
      .alertItemCTAContainer {
        position: relative;
        width: 100%;
        display: flex;
        justify-content: flex-end;
        @media (max-width: $mobile-breakpoint) {
          justify-content: center;
        }
        flex-grow: 0;
        > a {
          position: relative;
          display: block;
          overflow: hidden;
          -webkit-backface-visibility: hidden;
          -moz-backface-visibility: hidden;
          -webkit-transform: translate3d(0, 0, 0);
          -moz-transform: translate3d(0, 0, 0);
          text-align: center;
          text-decoration: none;
          margin: 20px 0;
          min-width: 300px;
          padding: 10px 30px;
          font-size: 14px;
          font-weight: $app-font-bold;
          background-color: $color-button-primary;
          box-shadow: $button-box-shadow;
          color: #ffffff;
          border: none;
          border-radius: 100px;
          outline: 0;
          cursor: pointer;
          &:hover {
            &:after {
              content: "";
              display: block;
              position: absolute;
              left: 0;
              top: 0;
              width: 100%;
              height: 100%;
              background-color: rgba(0, 0, 0, 0.1);
            }
          }
          &.disabled {
            background-color: $color-button-disable;
            box-shadow: none;
            pointer-events: none;
          }
          &[disabled] {
            pointer-events: none;
          }
          @media (max-width: $mobile-condense) {
            width: 98%;
            min-width: 200px;
          }
        }
      }
    }
  }
  .alertsEmptyContainer {
    padding: 80px 0 160px 0;
    h4.alertItemWeatherHeading {
      text-align: center;
    }
    .startButton {
      margin: 20px auto;
    }
  }
}

@import "../../components/alert-offers/AlertOffers.scss";
