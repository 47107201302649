.campaignNextPrevContainer {
  display: block;
  position: fixed;
  left: 50%;
  bottom: 0;
  overflow: hidden;
  transform: translate(-50%, 0%);
  transition: 0.3s transform ease-out;
  -webkit-user-select: none;
  cursor: pointer;
  &.hide {
    transform: translate(-50%, 200%);
    button {
      transform: translate(0%, -100%);
      transition: 0.3s transform ease-out;
      transition-delay: 0.3s;
    }
  }
  button {
    position: relative;
    display: flex;
    justify-content: center;
    align-content: center;
    align-items: center;
    padding: 22px 28px 22px 20px;
    margin: 0 0 90px 0;
    font-size: 16px;
    font-weight: $app-font-bold;
    background-color: $color-secondary;
    color: #ffffff;
    box-sizing: border-box;
    border: 2px solid $color-secondary;
    border-radius: 100px;
    outline: 0;
    transform: translate(0%, 0%);
    transition: none;
    transition-delay: 0;
    z-index: 100;
    cursor: pointer;
    /*&:hover{
            background-color:$color-active-highlight;
        }*/
    p {
      margin: 0;
    }
    .icon {
      display: inline-block;
      vertical-align: middle;
      margin-right: 10px;
      height: 1em;
      width: auto;
    }
    /* chevrons down animation */
    .chevronsContainer {
      position: relative;
      display: block;
      margin-right: 40px;
      height: 1em;
      width: auto;
      .chevron {
        position: absolute;
        width: 28px;
        height: 4px;
        opacity: 0;
        transform: scale3d(0.75, 0.75, 0.75);
        animation-name: move;
        animation-duration: 3s;
        animation-timing-function: linear;
        animation-iteration-count: infinite;
      }

      .chevron:first-child {
        animation-delay: 1s;
      }

      .chevron:nth-child(2n) {
        animation-delay: 2s;
      }

      .chevron:before,
      .chevron:after {
        content: " ";
        position: absolute;
        top: 0;
        height: 100%;
        width: 51%;
        background: #ffffff;
      }

      .chevron:before {
        left: 0;
        transform: skew(0deg, 30deg);
      }

      .chevron:after {
        right: 0;
        width: 50%;
        transform: skew(0deg, -30deg);
      }

      /*@keyframes move {
          0% {
            opacity: 0;
            transform: translateY(-10px) scale3d(1, 1, 1);
          }
          50% {
              opacity:1;
              transform: translateY(5px) scale3d(.75, .75, .75);
          }
          100% {
            opacity: 0;
            transform: translateY(20px) scale3d(.5, .5, .5);
          }
        }*/
      @keyframes move {
        0% {
          opacity: 0;
          transform: translateY(-10px) scale3d(0.75, 0.75, 0.75);
        }
        50% {
          opacity: 1;
          transform: translateY(5px) scale3d(0.75, 0.75, 0.75);
        }
        100% {
          opacity: 0;
          transform: translateY(20px) scale3d(0.75, 0.75, 0.75);
        }
      }
    }

    /* zoom in pulse animation */
    .animatedPulse {
      display: block;
      width: 100%;
      height: 100%;
      background: $color-secondary;
      opacity: 0.5;
      border-radius: 50%;
      position: absolute;
      animation-duration: 2s;
      animation-iteration-count: infinite;
      animation-name: zoomIn;
      border-radius: 200px;
      z-index: -1;
    }

    @keyframes zoomIn {
      0% {
        opacity: 0;
        transform: scale3d(1, 1, 1);
      }

      50% {
        opacity: 0.5;
      }

      100% {
        opacity: 0;
        transform: scale3d(1.2, 1.4, 1.2);
      }
    }
  }
}
