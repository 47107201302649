.splashOverlay {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 12000;
  h1 {
    text-align: center;
    @media (max-width: 400px) {
      font-size: 24px;
    }
  }
  a {
    display: block;
    text-decoration: none;
    padding: 8px 15px;
    margin-top: 40px;
    font-size: 14px;
    font-weight: $app-font-bold;
    background-color: $color-active;
    color: #ffffff;
    border: none;
    border-radius: 100px;
    outline: 0;
    cursor: pointer;
    &:hover {
      background-color: $color-active-highlight;
    }
  }
}
